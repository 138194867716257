
import React from 'react';
import PropTypes from 'prop-types';
import './SubHeaderLogo.css';

export default class SubHeaderLogo extends React.Component {
    render() {
        let extraClassName = '';
        if (this.props.className && this.props.className.length > 0) {
            extraClassName = ' ' + this.props.className;
        }
        return (
            <div className={`SubHeaderLogo${extraClassName}`}>
                <picture>
                    <img src={this.props.source} alt={this.props.alttext} />
                </picture>
            </div>
        );
    }
}


SubHeaderLogo.defaultProps = {
    source: '',
    alttext: ''
};

SubHeaderLogo.propTypes = {
    source: PropTypes.string,
    alttext: PropTypes.string
}