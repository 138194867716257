import React from "react";
import i18next from 'i18next';
import { scrollTo, getLocationHashId } from "../App";
import { withTranslation } from "react-i18next";
import BookishContainer from "../component/BookishContainer";
import SubHeaderLogo from "../component/SubHeaderLogo";
import BoxContainer from "../component/BoxContainer";
import Footer from "../component/Footer";
import IntroText from "../component/IntroText";
import "./iot.css";

import iot_logo_header from '../media/logo_iot_header.svg';
import logo_iot from '../media/logo_iot.svg';
import Carousel from '../component/Carousel';
import List from '../component/List';

import icon_datensammlung from '../media/icon_datensammlung.svg';
import icon_rechenzentrum from '../media/icon_rechenzentrum.svg';
import icon_visualisierung from '../media/icon_visualisierung.svg';
import icon_monitoring from '../media/icon_monitoring.svg';

import icon_remote_monitoring from '../media/icon_remote_monitoring.svg';
import icon_schnell_und_effizient from '../media/icon_schnell_und_effizient.svg';
import icon_ueberblick_behalten from '../media/icon_ueberblick_behalten.svg';
import icon_vermeiden_von_ausfallzeiten from '../media/icon_vermeiden_von_ausfallzeiten.svg';

import Hardware from "./Hardware";


class Iot extends React.Component {
    componentDidMount = () => {
        if (scrollTo(this.props.scrollto) === false) {
            if (scrollTo(getLocationHashId()) === false) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        }
    };

    componentDidUpdate = () => {
        scrollTo(this.props.scrollto);
    };

    render() {
        return (
            <div className="App-iot">
                {/* Header logo */}
                <BookishContainer
                    id='header-logo'
                    backgroundColor="#ffffff"
                    topLeftBorderBackgroundColor="#000000">
                    <SubHeaderLogo source={iot_logo_header}  />
                </BookishContainer>

                {/* Intro */}
                <BookishContainer backgroundColor="#c7282b" topLeftBorderBackgroundColor='#c7282b'
                    id='digitale-botschaft'>
                        <IntroText 
                            text={this.props.t("IoT.HeaderText")} 
                            headerText={this.props.t("IoT.Header")} 
                        />
                    {/* <DigitaleBotschaft /> */}
                </BookishContainer>

                { /* Beispiele - Hardware*/}
                <BoxContainer header={this.props.t("IoT.Hardware.Header")} iconsrc={logo_iot} backgroundColor='#red'>
                    <Hardware />
                </BoxContainer>

                { /* Beispiele - So funktioniert das System*/}
                <BookishContainer backgroundColor='#95a6b1'>
                    <SoFunktioniertDasSystem />
                </BookishContainer>

                { /* Beispiele - Die Software*/}
                <BoxContainer header={this.props.t("IoT.Software.Header")} iconsrc={logo_iot}>
                    <DieSoftware />
                </BoxContainer>
              
                { /* Unser Angebot */ }
                <BookishContainer backgroundColor='#95a6b1'>
                    <UnserAngebot />
                </BookishContainer>

                <BookishContainer
                    id="contact"
                    header={this.props.t("Common.Contact")}
                    textColor="#FFFFFF"
                    backgroundColor="#000000"
                    topLeftBorderBackgroundColor="#95a6b1"
                >
                    <Footer hasGoogleMaps={true} />
                </BookishContainer>
            </div>


        );
    }
}

export default withTranslation()(Iot);


function DieSoftware() {
    const carouselSrc = [
        { 'path': '/images/references/iot_software01_1400x791.webp', 'header': i18next.t("IoT.Software.Item1.Header"), 'text': i18next.t('IoT.Software.Item1.Text') },
        { 'path': '/images/references/iot_software02_1400x791.webp', 'header': i18next.t("IoT.Software.Item2.Header"), 'text': i18next.t('IoT.Software.Item2.Text') },
        { 'path': '/images/references/iot_software03_1400x791.webp', 'header': i18next.t("IoT.Software.Item3.Header"), 'text': i18next.t('IoT.Software.Item3.Text') },
        { 'path': '/images/references/iot_software04_1400x791.webp', 'header': i18next.t("IoT.Software.Item4.Header"), 'text': i18next.t('IoT.Software.Item4.Text') }
    ];
    return (
        <div className="App-references-webapp">
            <Carousel className="escape" aspectRatio={0.5625} src={carouselSrc} />
        </div>
    );
}

function SoFunktioniertDasSystem() {
    const listSrc = [
        { 'path': icon_datensammlung, 'header': i18next.t('IoT.Functionality.Data.Header'), 'text': i18next.t('IoT.Functionality.Data.Text') },
        { 'path': icon_rechenzentrum, 'header': i18next.t('IoT.Functionality.Hosting.Header'), 'text': i18next.t('IoT.Functionality.Hosting.Text') },
        { 'path': icon_visualisierung, 'header': i18next.t('IoT.Functionality.Visualization.Header'), 'text': i18next.t('IoT.Functionality.Visualization.Text') },
        { 'path': icon_monitoring, 'header': i18next.t('IoT.Functionality.Monitoring.Header'), 'text': i18next.t('IoT.Functionality.Monitoring.Text') }
    ];
    return (
        <div className="Iot-funktionsweise">
            <List header={i18next.t("IoT.Functionality.Header")} src={listSrc}/>
        </div>
    );
}

function UnserAngebot() {
    const listSrc = [
        { 'path': icon_ueberblick_behalten, 'header': i18next.t("IoT.Offer.Overview.Header"), 'text': i18next.t("IoT.Offer.Overview.Text") },
        { 'path': icon_schnell_und_effizient, 'header': i18next.t("IoT.Offer.Fast.Header"), 'text': i18next.t("IoT.Offer.Fast.Text") },
        { 'path': icon_vermeiden_von_ausfallzeiten, 'header': i18next.t("IoT.Offer.Downtimes.Header"), 'text': i18next.t("IoT.Offer.Downtimes.Text") },
        { 'path': icon_remote_monitoring, 'header': i18next.t("IoT.Offer.Monitoring.Header"), 'text': i18next.t("IoT.Offer.Monitoring.Text") }
    ];
    return (
        <div className="NewsRodeo-unser-angebot">
            <List header={i18next.t("IoT.Offer.Header")} src={listSrc}/>
        </div>
    );
}