import React from 'react';
import PropTypes from 'prop-types';
import './BoxContainer.css';

export default class BoxContainer extends React.Component {
    render() {
        let boxContainerId = null;
        if (this.props.id && this.props.id.length > 0) {
            boxContainerId = this.props.id;
        }
        let extraClassName = '';
        if (this.props.className && this.props.className.length > 0) {
            extraClassName = ' ' + this.props.className;
        }
        let headerDiv;
        if (this.props.header && this.props.header.length > 0) {
            headerDiv = (
                <div className="BoxContainer-box-header">
                    <h1 className="eurostile-condensed-heavy">{this.props.header}</h1>
                </div>
            );
        }
        let iconDiv;
        if (this.props.iconsrc && this.props.iconsrc.length > 0) {
            iconDiv = (
                <div className="BoxContainer-box-icon">
                    <img src={this.props.iconsrc} alt="icon" />
                </div>
            );
        }
        return (
            <div id={boxContainerId} className={`BoxContainer${extraClassName}`}>
                <div className="BoxContainer-box">
                    {iconDiv}
                    {headerDiv}
                </div>
                <div className="BoxContainer-inner">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

BoxContainer.defaultProps = {
    header: '',
    iconsrc: ''
};

BoxContainer.propTypes = {
    header: PropTypes.string,
    iconsrc: PropTypes.string
}