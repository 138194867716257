import React from 'react';
import PropTypes from 'prop-types';
import { getCookie, setCookie } from '../App'
import { Player } from '@lottiefiles/react-lottie-player';
import { withTranslation } from 'react-i18next';
import BorderButton from './BorderButton'
import lottie_react from '../media/lottie_83300_react_native.json';
import logo from '../media/logo_pos_screen.svg';
import email from '../media/email_f7a823.svg';
import phone from '../media/phone_f7a823.svg';
import linkedin_f7a824 from '../media/linkedin_f7a824.svg';
import linkedin_ffffff from '../media/linkedin_ffffff.svg';
import googlemaps_icon from '../media/googlemaps_icon.svg';
import googlemaps_privacy_de from '../media/googlemaps_privacy_de.svg';
import './Footer.css';

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasGoogleMapsPrivacy: getCookie('emmgt_at_googlemapsprivacyaccepted') === 'true' };
        this.solvencyClick = this.solvencyClick.bind(this);
        this.googleMapsPrivacyAcceptedClick = this.googleMapsPrivacyAcceptedClick.bind(this);
    }

    solvencyClick() {
        window.open("https://zertifikat.creditreform.at/anzeige_2021.php?code=xaQIuHGbBLFMa9WdLPnnytfB1eLk96SI&height=0", "_blank").focus();
    }

    googleMapsPrivacyAcceptedClick() {
        setCookie('emmgt_at_googlemapsprivacyaccepted', 'true', 30);
        this.setState({ hasGoogleMapsPrivacy: true });
    }

    render() {
        let googleMaps;
        if (this.props.hasGoogleMaps === true || this.props.hasGoogleMaps === 'true' || this.props.hasGoogleMaps === 'True') {
            if (this.state.hasGoogleMapsPrivacy === true || this.state.hasGoogleMapsPrivacy === 'true' || this.state.hasGoogleMapsPrivacy === 'True') {
                googleMaps = (
                    <div className="App-footer-maps">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10646.749877732358!2d14.0436005!3d48.1548302!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x38279f73954bf42e!2sEbner%20Media%20%26%20Management%20G.m.b.H.!5e0!3m2!1sde!2sat!4v1637691946219!5m2!1sde!2sat"
                                width="100%"
                                height="320"
                                style={{border:0}}
                                loading="lazy"
                                title="Google Maps - Location"></iframe>
                    </div>
                );
            }
            else {
                googleMaps = (
                    <div className="App-footer-maps">
                        <div className="App-footer-maps-privacy">
                            <img className="background" src="/images/footer/MapsBlurred.webp" alt="Google Maps" />
                            <div className="App-footer-maps-privacy-overlay" onClick={this.googleMapsPrivacyAcceptedClick}>
                                <img className="icon" src={googlemaps_icon} alt="Google Maps Icon" />
                                <br />
                                <img className="info" src={googlemaps_privacy_de} alt="Google Maps Privacy" />
                            </div>
                            <a href="https://policies.google.com/privacy?hl=de&amp;gl=at" rel="noreferrer" target="_blank"></a>
                        </div>
                    </div>
                );
            }
        }
        let poweredByReact;
        let footerClassName = "App-footer";
        if (this.props.hasDownsize === true || this.props.hasDownsize === 'true' || this.props.hasDownsize === 'True') {
            footerClassName = "App-footer downsize";
        } else {
            poweredByReact = (
                <div className="App-footer-react">
                    <div className="App-footer-react-inner">
                        <span>powered by <a href="https://reactjs.org/" target="_blank" rel="noreferrer">react</a></span>
                        <div className="App-footer-react-lottie">
                            <Player autoplay loop src={lottie_react} />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <footer className={footerClassName}>
                <div className="App-footer-logo">
                    <img src={logo} alt="logo" />
                </div>
                <div className="App-footer-email">
                    <img src={email} alt="email" />
                    <span>office@posscreen.at</span>
                </div>
                <div className="App-footer-phone">
                    <img src={phone} alt="phone" />
                    <span>+43 (0) 72 42 / 25 20 50</span>
                </div>
                <div className="App-footer-address">
                    <span>Jägerweg 4</span>
                    <span>4600 Thalheim bei Wels</span>
                    <span>Österreich</span>
                </div>
                <div className="App-footer-follow-us">
                    <div className="App-footer-follow-us-text">{this.props.t('Footer.FollowUs')}</div>
                    <div className="App-footer-follow-us-linkedin">
                        <a href="https://linkedin.com/company/pos-screen-gmbh" rel="noreferrer" target="_blank">
                            <img src={linkedin_f7a824} className="normal" alt="linkedin" />
                            <img src={linkedin_ffffff} className="hover" alt="linkedin-hover" />
                        </a>
                    </div>
                </div>
                {googleMaps}
                <div className="App-footer-actions">
                    <BorderButton className="BorderButton-datenschutz" text={this.props.t('Common.DataPrivacy')} navigateto="/datenschutz" />
                    <BorderButton className="BorderButton-solvency" text={this.props.t('Common.Solvency')} onClick={this.solvencyClick} />
                    <BorderButton className="BorderButton-impressum" text={this.props.t('Common.LegalNotice')} navigateto="/impressum" />
                </div>
                {poweredByReact}
            </footer>
        );
    }
}

export default withTranslation()(Footer)

Footer.defaultProps = {
    hasGoogleMaps: false,
    hasDownsize: false,
};

Footer.propTypes = {
    hasGoogleMaps: PropTypes.bool,
    hasDownsize: PropTypes.bool
}