import React from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import { scrollTo, getLocationHashId } from '../App'
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import BookishContainer from '../component/BookishContainer'
import BoxContainer from '../component/BoxContainer'
import Carousel from '../component/Carousel'
import ImageGrid from '../component/ImageGrid'
import Slogan from '../component/Slogan'
import Footer from '../component/Footer'
import lottie_team from '../media/lottie_early_bird.json';

import './References.css';

class References extends React.Component {

    constructor(props) {
        super(props);
        this.state = { selectedImageIndex: 0 };
    }

    componentDidMount() {
        if (scrollTo(this.props.scrollto) === false) {
            if (scrollTo(getLocationHashId()) === false) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        }
    }

    componentDidUpdate() {
        scrollTo(this.props.scrollto);
    }

    render() {
        return (
            <div className="App-references">
                <div className='App-references-top-right-corner'>
                    <div className='App-references-top-right-corner-inner'></div>
                </div>

                {/* Slogan */}
                <BookishContainer className="App-slogan-container">
                    <Slogan className="App-slogan" />
                </BookishContainer>

                {/* Intro */}
                <BookishContainer id="intro" header={this.props.t('Common.References')} backgroundColor="#EBECF0" topLeftBorderBackgroundColor="#FFFFFF">
                    <IntroSection />
                </BookishContainer>

                <KundenImageGrid imageSelectedHandler={this.imageSelectedHandler} selectedImageIndex={this.state.selectedImageIndex} />

                {/* Kunden */}
                <BoxContainer id="kunden" header={this.props.t('Common.Customers')}>
                    <Kunden itemSelectedHandler={this.itemSelectedHandler} selectedImageIndex={this.state.selectedImageIndex} />
                </BoxContainer>

                {/* Contact */}
                <BookishContainer id="contact" textColor="#FFFFFF" backgroundColor="#000000" topLeftBorderBackgroundColor="#F5F6F8">
                    <Footer hasDownsize />
                </BookishContainer>
            </div>
        );
    }

    imageSelectedHandler = (e) => {
        this.setState({ selectedImageIndex: parseInt(e.target.dataset.index) })
    }

    itemSelectedHandler = (index) => {
        this.setState({ selectedImageIndex: index });
    }
}


export default withTranslation()(References)



References.defaultProps = {
    selectedCustomerHeader: '',
    scrollto: '',
    selectedImageIndex: 0
};

References.propTypes = {
    selectedCustomerHeader: PropTypes.string,
    scrollto: PropTypes.string,
    selectedImageIndex: PropTypes.number
}

function KundenImageGrid({ imageSelectedHandler, selectedImageIndex }) {
    const gridSrc = [
        { 'path': '/images/references/Logo_Raiffeisen.webp', 'customer': 'Raiffeisen' },
        { 'path': '/images/references/Logo_Airbus.webp', 'customer': 'Airbus' },
        { 'path': '/images/references/Logo_OOEVerkehrsbund.webp', 'customer': 'OÖVV' },
        { 'path': '/images/references/Logo_CCAmstetten.webp', 'customer': 'CCA' },
        { 'path': '/images/references/Logo_McDonalds.webp', 'customer': 'McDonalds' },
        { 'path': '/images/references/Logo_Varena.webp', 'customer': 'Varena' },
        { 'path': '/images/references/Logo_Wels.webp', 'customer': 'Wels' },
        { 'path': '/images/references/Logo_EskoleLeiner.webp', 'customer': 'Eskole' },
        { 'path': '/images/references/Logo_Rosenarcade.webp', 'customer': 'Rosen Arcade' },
        { 'path': '/images/references/Logo_Gore.webp', 'customer': 'Gore' },
        { 'path': '/images/references/Logo_StadionCenter.webp', 'customer': 'Stadion Center' },
    ]

    return (
        <div className='Referenzen-image-grid-container'>
            <ImageGrid src={gridSrc} itemBackgroundColor='#ffffff' backgroundColor='#f5f6f8' imageSelected={imageSelectedHandler} selectedImageIndex={selectedImageIndex} />
        </div>
    );
}
KundenImageGrid.propTypes = {
    imageSelectedHandler: PropTypes.func,
    selectedImageIndex: PropTypes.number
}


function Kunden({ itemSelectedHandler, selectedImageIndex }) {
    const carouselSrc = [
        { 'path': '/images/references/Raikka_Landesbank_1400x791.webp', 'header': 'Raiffeisen', 'text': i18next.t('References.Raiffeisen') },
        { 'path': '/images/references/AirbusBild_1400x791.webp', 'header': 'Airbus', 'text': i18next.t('References.Airbus') },
        { 'path': '/images/references/RiedBusterminal01_1400x791.webp', 'header': 'OÖVV', 'text': i18next.t('References.OÖVV') },
        { 'path': '/images/references/CityCenterAmstetten2_1400x791.webp', 'header': 'CCA', 'text': i18next.t('References.CCA') },
        { 'path': '/images/references/McDonalds1_1400x791.webp', 'header': 'McDonalds', 'text': i18next.t('References.McDonalds') },
        { 'path': '/images/references/Varena_1400x791.webp', 'header': 'Varena', 'text': i18next.t('References.Varena') },
        { 'path': '/images/references/GreifWels_1400x791.webp', 'header': 'Wels', 'text': i18next.t('References.Wels') },
        { 'path': '/images/references/EskoleLeiner_1400x791.webp', 'header': 'Eskole', 'text': i18next.t('References.Eskole') },
        { 'path': '/images/references/Rosenarcade_1400x791.webp', 'header': 'Rosen Arcade', 'text': i18next.t('References.RosenArcade') },
        { 'path': '/images/references/Gore_1400x791.webp', 'header': 'Gore', 'text': i18next.t('References.Gore') },
        { 'path': '/images/references/Stadioncenter_1400x791.webp', 'header': 'Stadion Center', 'text': i18next.t('References.StadionCenter') },
    ];
    return (
        <div className="Referenzen-container">
            <div className='Referenzen-carousel-container'>
                <Carousel className="escape" aspectRatio={0.5625} src={carouselSrc}
                    itemSelectedHandler={itemSelectedHandler} selectedImageIndex={selectedImageIndex} />
            </div>
        </div>
    );
}
Kunden.propTypes = {
    itemSelectedHandler: PropTypes.func,
    selectedImageIndex: PropTypes.number
}


function IntroSection() {
    return (
        <div className="App-references-intro">
            <div className="animation">
                <Player autoplay loop src={lottie_team} />
            </div>
        </div>
    );
}