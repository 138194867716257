import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import lottie_early_bird from '../media/lottie_early_bird.json';
import Slogan from '../component/Slogan'
import './HomeIntro.css';

export default class HomeIntro extends React.Component {
    render() {
        return (
            <div className="App-home-intro">
                <Slogan className="App-home-intro-slogan" />
                <div className="App-home-intro-animation">
                    <Player autoplay loop src={lottie_early_bird} />
                </div>
            </div>
        );
    }
}