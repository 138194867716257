import React from 'react';
import { withTranslation } from 'react-i18next';
import './HomeServiceProvider.css';
import {createMarkup} from '../MarkupHelper';

class HomeServiceProvider extends React.Component {
    render() {
        return (
            <div className="App-home-service-provider" 
            dangerouslySetInnerHTML={createMarkup(this.props.t('HomeServiceProvider.Text'))}>
                
            </div>
        );
    }
}

export default withTranslation()(HomeServiceProvider);