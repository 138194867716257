import React from 'react';
import PropTypes from 'prop-types';
import './TextSectionList.css'
import TextSection from '../component/TextSection';

export default class TextSectionList extends React.Component {
    render() {
        const items = [];
        const length = this.countItems()

        for (let i = 0; i < length; i++) 
        {
            const itemContainer = <TextSection {...this.props.src[i]} key={'text-section-item-' + i} />;
            items.push(itemContainer);
        }

        return (
            <div className='TextSectionList-container'>
                <div className='TextSectionList-items-container'>
                    {items.map((Item, i) => Item)}
                </div>
            </div>
        );
    };

    getItem(index) {
        let count = this.countItems();
        if (count > 0 && count > index && index >= 0) {
            return this.props.src[index];
        }
        return undefined;
    }
    
    countItems() {
        if (this.props.src && this.props.src.length > 0) {
            return this.props.src.length;
        }
        return 0;
    }
};

TextSectionList.defaultProps = {
    src: []
};

TextSectionList.propTypes = {
    src: PropTypes.array,
}