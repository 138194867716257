import React from 'react';
import './Slogan.css';

export default class Slogan extends React.Component {
    render() {
        let extraClassName = '';
        if (this.props.className && this.props.className.length > 0) {
            extraClassName = ' ' + this.props.className;
        }
        return (
            <div className={`Slogan${extraClassName}`}>
                <div className="Slogan-inline">
                    <div>
                        <span className="eurostile-condensed">we are</span>
                        <span className="eurostile-condensed-heavy">your</span>
                    </div>
                    <div className="eurostile-condensed-heavy">
                        <span className="Slogan-inline-span-1">early bird </span>
                    </div>
                </div>
            </div>
        );
    }
}
