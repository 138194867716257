import React from "react";
import i18next from 'i18next';
import { scrollTo, getLocationHashId } from "../App";
import { withTranslation } from "react-i18next";
import BookishContainer from "../component/BookishContainer";
import SubHeaderLogo from "../component/SubHeaderLogo";
import BoxContainer from "../component/BoxContainer";
import Footer from "../component/Footer";
import IntroText from "../component/IntroText";
import "./infopop.css";

import infopop_logo_header from '../media/logo_infopop_header.svg';
import logo_infopop from '../media/logo_infopop.svg';
import Carousel from '../component/Carousel';
import List from '../component/List';

import icon_euro from '../media/icon_euro.svg';
import icon_dots from '../media/icon_dots.svg';
import icon_usb from '../media/icon_usb.svg';
import icon_thumbsup from '../media/icon_thumbsup.svg';
import icon_check from '../media/icon_check.svg';
import icon_magnifying from '../media/icon_magnifying.svg';
import icon_chat from '../media/icon_chat.svg';
import icon_tag from '../media/icon_tag.svg';



class InfoPop extends React.Component {
    componentDidMount = () => {
        if (scrollTo(this.props.scrollto) === false) {
            if (scrollTo(getLocationHashId()) === false) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        }
    };

    componentDidUpdate = () => {
        scrollTo(this.props.scrollto);
    };

    render() {
        return (
            <div className="App-infopop">
                {/* Header logo */}
                <BookishContainer
                    id='header-logo'
                    backgroundColor="#ffffff"
                    topLeftBorderBackgroundColor="#000000">
                    <SubHeaderLogo source={infopop_logo_header}  />
                </BookishContainer>

                {/* Intro */}
                <BookishContainer backgroundColor="#4cb2d7" topLeftBorderBackgroundColor='#4cb2d7'
                    id='digitale-botschaft'>
                        <IntroText 
                            text={this.props.t("InfoPop.HeaderText")} 
                            headerText={this.props.t("InfoPop.Header")}
                        />
                </BookishContainer>

                { /* Anwendungsbeispiele */}
                <BoxContainer header={this.props.t("InfoPop.Examples.Header")} iconsrc={logo_infopop}>
                    <this.Anwendungsbeispiele/>
                </BoxContainer>

                { /* Vorteil */}
                <BookishContainer backgroundColor='#95a6b1'>
                    <this.IhrVorteil />
                </BookishContainer>

                <BookishContainer
                    id="contact"
                    header={this.props.t("Common.Contact")}
                    textColor="#FFFFFF"
                    backgroundColor="#000000"
                    topLeftBorderBackgroundColor="#95a6b1"
                >
                    <Footer hasGoogleMaps={true} />
                </BookishContainer>
            </div>


        );
    }

    IhrVorteil = () => {
        const listSrc = [
            { 'path': icon_euro, 'header': i18next.t("InfoPop.Advantage.Money.Header"), 'text': i18next.t('InfoPop.Advantage.Money.Text') },
            { 'path': icon_dots, 'header': i18next.t("InfoPop.Advantage.Fast.Header"), 'text': i18next.t('InfoPop.Advantage.Fast.Text') },
            { 'path': icon_usb, 'header': i18next.t("InfoPop.Advantage.Flexible.Header"), 'text': i18next.t('InfoPop.Advantage.Flexible.Text') },
            { 'path': icon_thumbsup, 'header': i18next.t("InfoPop.Advantage.Easy.Header"), 'text': i18next.t('InfoPop.Advantage.Easy.Text') },
            { 'path': icon_check, 'header': i18next.t("InfoPop.Advantage.ELearning.Header"), 'text': i18next.t('InfoPop.Advantage.ELearning.Text') },
            { 'path': icon_magnifying, 'header': i18next.t("InfoPop.Advantage.Search.Header"), 'text': i18next.t('InfoPop.Advantage.Search.Text') },
            { 'path': icon_chat, 'header': i18next.t("InfoPop.Advantage.Videochat.Header"), 'text': i18next.t('InfoPop.Advantage.Videochat.Text') },
            { 'path': icon_tag, 'header': i18next.t("InfoPop.Advantage.Tagging.Header"), 'text': i18next.t('InfoPop.Advantage.Tagging.Text') }
        ];
        return (
            <div className="Infopop-ihr-vorteil">
                <List header={i18next.t("InfoPop.Advantage.Header")} src={listSrc}/>
            </div>
        );
    }
    
    Anwendungsbeispiele() {
        const carouselSrc = [
            { 'path': '/images/references/Handshake_1400x791.webp', 'header': i18next.t('InfoPop.Examples.Onboarding.Header'), 'text': i18next.t('InfoPop.Examples.Onboarding.Text') },
            { 'path': '/images/references/ManHeadset_1400x791.webp', 'header': i18next.t('InfoPop.Examples.Certificate.Header'), 'text': i18next.t('InfoPop.Examples.Certificate.Text') },
            { 'path': '/images/references/Teamwork_1400x791.webp', 'header': i18next.t('InfoPop.Examples.Communication.Header'), 'text': i18next.t('InfoPop.Examples.Communication.Text') },
            { 'path': '/images/references/InfoPopScreen_1400x791.webp', 'header': i18next.t('InfoPop.Examples.Application.Header'), 'text': i18next.t('InfoPop.Examples.Application.Text') },
        ];
        return (
            <div className="Infopop-anwendungsbeispiele">
                <Carousel className="escape" aspectRatio={0.5625} src={carouselSrc} />
            </div>
        );
    }
}

export default withTranslation()(InfoPop);

