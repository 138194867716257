import React from 'react';
import PropTypes from 'prop-types';
import './ImageGrid.css';

export default class ImageGrid extends React.Component {

    render() {
        const items = [];
        const length = this.countItems()

        for (let i = 0; i < length; i++) {
            const imagePath = this.getPath(i);
            const imagePathJpg = this.changeExt(imagePath, 'jpg');
            const imageAltText = this.getNameFromPath(imagePath);
            const itemContainerId = `image-item-${i}`;
            const imageId = `image-${i}`;
            const isSelected = this.props.selectedImageIndex === i;

            const itemContainer = (
            <div id={itemContainerId} key={itemContainerId} className={`ImageGrid-item-container ${isSelected ? 'selected' : ''}`}>
                <picture data-index={i} id={imageId} onClick={this.props.imageSelected}>
                    <source data-index={i} srcSet={imagePath} type="image/webp" />
                    <img data-index={i} src={imagePathJpg} alt={imageAltText} />
                </picture>
                
            </div>);
            items.push(itemContainer);
        }

        return (
            <div className='ImageGrid-container'>
                <div className='ImageGrid-items-container'>
                    {items.map((Item, i) => Item)}
                </div>
            </div>);

    }


    countItems() {
        if (this.props.src && this.props.src.length > 0) {
            return this.props.src.length;
        }
        return 0;
    }

    getItem(index) {
        let count = this.countItems();
        if (count > 0 && count > index && index >= 0) {
            return this.props.src[index];
        }
        return undefined;
    }

    hasItem(index) {
        let item = this.getItem(index);
        if (item) {
            return true;
        }
        return false;
    }

    getPath(index) {
        let item = this.getItem(index);
        if (item && item.path) {
            return item.path;
        }
        return undefined;
    }

    getNameFromPath(path) {
        try {
            if (path && path.length > 0) {
                return path.split('/').pop().split('.').shift();
            }
        }
        catch (e) { }
        return '';
    }

    changeExt(fileName, newExt) {
        var pos = fileName.includes(".") ? fileName.lastIndexOf(".") : fileName.length
        var fileRoot = fileName.substr(0, pos)
        var output = `${fileRoot}.${newExt}`
        return output
      }
}

ImageGrid.defaultProps = {
    src: [],
    itemBackgroundColor: '',
    backgroundColor: '',
    imageSelected: null,
    selectedImageIndex: 0
};

ImageGrid.propTypes = {
    src: PropTypes.array,
    itemBackgroundColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    imageSelected: PropTypes.func,
    selectedImageIndex: PropTypes.number
}