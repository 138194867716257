import React from 'react';
import { withTranslation } from 'react-i18next';
import ArrowButton from '../component/ArrowButton'
import logo_newsrodeo from '../media/logo_newsrodeo_header.svg';
import logo_iot from '../media/logo_iot_header.svg';
import logo_infopop from '../media/logo_infopop_header.svg';
import logo_passengerinfo from '../media/logo_passengerinfo_header.svg';
import './HomeProducts.css';
import {createMarkup} from '../MarkupHelper';

class HomeProducts extends React.Component {

    constructor(props) {
        super(props);
        this.moreClickNewsRodeo = this.moreClickNewsRodeo.bind(this);
        this.moreClickIotMesh = this.moreClickIotMesh.bind(this);
        this.moreClickInfoPop = this.moreClickInfoPop.bind(this);
    }

    moreClickNewsRodeo() {
        window.open('/newsrodeo', '_blank', 'noopener,noreferrer');
    }

    moreClickIotMesh() {
        window.open('/iot', '_blank', 'noopener,noreferrer');
    }

    moreClickInfoPop() {
        window.open('/infopop', '_blank', 'noopener,noreferrer');
    }

    render() {
        return (
            <div className="App-home-products">
                <div className="App-home-products-header" dangerouslySetInnerHTML={createMarkup(this.props.t("HomeProducts.Header"))}></div>
                <div className="App-home-products-logo-container">
                    <div className="App-home-products-logo">
                        <img className="mobile product-icon" src={logo_newsrodeo} alt="newsrodeo mobile" />
                        <img className="desktop product-icon" src={logo_newsrodeo} alt="newsrodeo desktop" />
                        <div className="App-home-products-logo-text" dangerouslySetInnerHTML={createMarkup(this.props.t("HomeProducts.NewsRodeo"))}></div>
                        <div className='ArrowButton-container'>
                            <ArrowButton className="ArrowButton-more" text={this.props.t('Common.More')} onClick={this.moreClickNewsRodeo} />
                        </div>
                    </div>
                    <div className="App-home-products-logo">
                        <img className="mobile product-icon" src={logo_iot} alt="iot mobile" />
                        <img className="desktop product-icon" src={logo_iot} alt="iot desktop" />
                        <div className="App-home-products-logo-text" dangerouslySetInnerHTML={createMarkup(this.props.t("HomeProducts.IoT"))}></div>
                        <div className='ArrowButton-container'>
                            <ArrowButton className="ArrowButton-more" text={this.props.t('Common.More')} onClick={this.moreClickIotMesh} />
                        </div>
                    </div>
                    <div className="App-home-products-logo">
                        <img className="mobile product-icon" src={logo_infopop} alt="infopop mobile" />
                        <img className="desktop product-icon" src={logo_infopop} alt="infopop desktop" />
                        <div className="App-home-products-logo-text" dangerouslySetInnerHTML={createMarkup(this.props.t("HomeProducts.InfoPop"))}></div>
                        <div className='ArrowButton-container'>
                            <ArrowButton className="ArrowButton-more" text={this.props.t('Common.More')} onClick={this.moreClickInfoPop} />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default withTranslation()(HomeProducts);