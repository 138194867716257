import React from 'react';
import { withTranslation } from 'react-i18next';
import './Hardware.css';
import iot_hardware from '../media/iot_hardware_1400x1045.webp';
import { createMarkup } from '../MarkupHelper';

class Hardware extends React.Component {
    render() {
        return (
            <div className='Hardware-container'>
                <img src={iot_hardware} alt='IOT-Hardware' />
                <span dangerouslySetInnerHTML={createMarkup(this.props.t("IoT.Hardware.Text"))}>
                    
                </span>
            </div>
        );
    }
}

export default withTranslation()(Hardware);
