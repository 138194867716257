import React from 'react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import './passengerinfo.css'
import Carousel from '../component/Carousel';
import TextSectionList from '../component/TextSectionList';


class PassengerInfo extends React.Component {

    render() {
        return (
            <div className='PassengerInfo-container'>
                <div className='PassengerInfo-headline-container'>
                    <HeadlineSection />
                </div>
                <div className='PassengerInfo-intro-container'>
                    <IntroTextSection />
                </div>
                <div className='PassengerInfo-funktionsumfang-container'>
                    <FunktionsumfangSection/>
                </div>
            </div>
        );
    };
}
export default withTranslation()(PassengerInfo);

function HeadlineSection() {
    const carouselSrc = [
        { 'path': 'images/newsrodeo/NR-Fahrplan-Stele.svg' },
        { 'path': 'images/newsrodeo/NR-Fahrplan-Stele.svg' }
    ];
    return (
        <div className='PassengerInfo-intro'>
            <Carousel className='escape' aspectRatio={0.5625} src={carouselSrc} />
        </div>
    );
}

function IntroTextSection(){
    var src = [
        { 'title': i18next.t('NewsRodeo.PassengerInfo.Intro.Part1.Header'), 'subtitle': i18next.t('NewsRodeo.PassengerInfo.Intro.Part1.SubTitle'), 'text': i18next.t('NewsRodeo.PassengerInfo.Intro.Part1.Text') },
        { 'subtitle': i18next.t('NewsRodeo.PassengerInfo.Intro.Part2.SubTitle'), 'text': i18next.t('NewsRodeo.PassengerInfo.Intro.Part2.Text') },
    ];
    return <TextSectionList src={src} />;
}

function FunktionsumfangSection(){
    var src = [
        { 'subtitle': i18next.t('NewsRodeo.PassengerInfo.Functionality.Sections.Part1.SubTitle'), 'text': i18next.t('NewsRodeo.PassengerInfo.Functionality.Sections.Part1.Text') },
        { 'subtitle': i18next.t('NewsRodeo.PassengerInfo.Functionality.Sections.Part2.SubTitle'), 'text': i18next.t('NewsRodeo.PassengerInfo.Functionality.Sections.Part2.Text') },
        { 'subtitle': i18next.t('NewsRodeo.PassengerInfo.Functionality.Sections.Part3.SubTitle'), 'text': i18next.t('NewsRodeo.PassengerInfo.Functionality.Sections.Part3.Text') },
        { 'subtitle': i18next.t('NewsRodeo.PassengerInfo.Functionality.Sections.Part4.SubTitle'), 'text': i18next.t('NewsRodeo.PassengerInfo.Functionality.Sections.Part4.Text') },
        { 'subtitle': i18next.t('NewsRodeo.PassengerInfo.Functionality.Sections.Part5.SubTitle'), 'text': i18next.t('NewsRodeo.PassengerInfo.Functionality.Sections.Part5.Text') },
    ];
    return (
        <div className='Funktionsumfang-section'>
            <h2 className='eurostile-condensed-heavy'>{i18next.t('NewsRodeo.PassengerInfo.Functionality.Header')}</h2>
            <TextSectionList src={src} />
            <div className='Funktionsumfang-image'>
                <img src='images/newsrodeo/iStock_Haltestelle.svg' alt='iStock_Haltestelle' />
            </div>
        </div>
    );
}