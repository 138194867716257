import React from 'react';
import i18next from 'i18next';
import { scrollTo, getLocationHashId } from '../App';
import { withTranslation } from 'react-i18next';
import BookishContainer from '../component/BookishContainer';
import IntroText from '../component/IntroText';
import Funktionsweise from './Funktionsweise';
import PassengerInfo from './passengerinfo';
import SubHeaderLogo from '../component/SubHeaderLogo';
import BoxContainer from '../component/BoxContainer';
import Footer from '../component/Footer';
import './newsrodeo.css';
import newsrodeo_logo_header from '../media/logo_newsrodeo_header.svg';
import logo_newsrodeo from '../media/logo_newsrodeo.svg';
import Carousel from '../component/Carousel';
import List from '../component/List';
import ArrowButton from '../component/ArrowButton'
import TextSection from '../component/TextSection';
import logo_passengerinfo from '../media/logo_newsrodeo_passengerinfo.svg'
import icon_cursor from '../media/icon_cursor.svg';
import icon_dots from '../media/icon_dots.svg';
import icon_euro from '../media/icon_euro.svg';
import icon_thumbsup from '../media/icon_thumbsup.svg';
import icon_konzept from '../media/icon_konzept.svg';
import icon_hardware from '../media/icon_hardware.svg';
import icon_software from '../media/icon_software.svg';
import icon_grafik from '../media/icon_grafik.svg';
import icon_wartung from '../media/icon_wartung.svg';
import icon_support from '../media/icon_support.svg';

class NewsRodeo extends React.Component {
    componentDidMount = () => {
        if (scrollTo(this.props.scrollto) === false) {
            if (scrollTo(getLocationHashId()) === false) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        }
    };

    componentDidUpdate = () => {
        scrollTo(this.props.scrollto);
    };

    render() {
        return (
            <div className='App-newsrodeo'>
                {/* Header logo */}
                <BookishContainer
                    id='header-logo'
                    backgroundColor='#ffffff'
                    topLeftBorderBackgroundColor='#000000'>
                    <SubHeaderLogo source={newsrodeo_logo_header} />
                </BookishContainer>

                {/* Intro */}
                <BookishContainer backgroundColor='#f7a823' topLeftBorderBackgroundColor='#f7a823'
                    id='digitale-botschaft'>
                    <IntroText
                        text={this.props.t('NewsRodeo.Intro.Text')}
                        headerText={this.props.t('NewsRodeo.Intro.Header')}
                    />
                </BookishContainer>

                { /* Beispiele - Digitales Schaufenster*/}
                <BoxContainer header={this.props.t('NewsRodeo.Billboard.Header')} iconsrc={logo_newsrodeo}>
                    <DigitaleSchaufensterSection />
                </BoxContainer>

                { /* Beispiele - Infodisplay*/}
                <BoxContainer header={this.props.t('NewsRodeo.Infodisplay.Header')} iconsrc={logo_newsrodeo}>
                    <InfodisplaySection />
                </BoxContainer>

                { /* Beispiele - Interaktives Infosystem*/}
                <BoxContainer header={this.props.t('NewsRodeo.Interactive.Header')} iconsrc={logo_newsrodeo}>
                    <InteraktivesInfosystemSection />
                </BoxContainer>

                { /* Beispiele - Raum- und Türschilder*/}
                <BoxContainer header={this.props.t('NewsRodeo.Doordisplay.Header')} iconsrc={logo_newsrodeo}>
                    <RaumUndTuerschilderSection />
                </BoxContainer>
                         
                { /* Vorteile */}
                <BookishContainer backgroundColor='#95a6b1'>
                    <IhrVorteilSection />
                </BookishContainer>

                { /* So funktioniert das System*/}
                <BookishContainer backgroundColor='#ffffff'>
                    <Funktionsweise />
                </BookishContainer>

                { /* Unser Angebot */}
                <BookishContainer backgroundColor='#95a6b1'>
                    <UnserAngebot />
                </BookishContainer>

                {/* PassengerInfo */}
                <BoxContainer header='PASSENGER INFO' iconsrc={logo_passengerinfo}>
                    <PassengerInfo />
                </BoxContainer>

                { /* Wir garantieren */ }
                <BookishContainer backgroundColor='#95a6b1'>
                    <WirGarantieren />
                </BookishContainer>

                { /* Hardware */ }
                <BookishContainer backgroundColor='#f5f6f8'>
                    <Hardware />
                </BookishContainer>

                <BookishContainer
                    id='contact'
                    header={this.props.t('Common.Contact')}
                    textColor='#FFFFFF'
                    backgroundColor='#000000'
                    topLeftBorderBackgroundColor='#f5f6f8'>
                    <Footer hasGoogleMaps={true} />
                </BookishContainer>
            </div>
        );
    }
}

export default withTranslation()(NewsRodeo);

function DigitaleSchaufensterSection() {
    const carouselSrc = [
        { 'path': '/images/references/Raikka_DigitalesSchaufenster_1400x791.webp', 'header': i18next.t('NewsRodeo.Billboard.Header'), 'text': i18next.t('NewsRodeo.Billboard.Entry1') },
        { 'path': '/images/references/Raikka_Landesbank_1400x791.webp', 'header': i18next.t('NewsRodeo.Billboard.Header'), 'text': i18next.t('NewsRodeo.Billboard.Entry2') }
    ];
    return (
        <div className='App-references-webapp'>
            <Carousel className='escape' aspectRatio={0.5625} src={carouselSrc} />
        </div>
    );
}

function InfodisplaySection() {
    const carouselSrc = [
        { 'path': '/images/references/Raikka_Infodisplay_1400x791.webp', 'header': i18next.t('NewsRodeo.Infodisplay.Header'), 'text': i18next.t('NewsRodeo.Infodisplay.Entry1') },
        { 'path': '/images/references/GreifWels_1400x791.webp', 'header': i18next.t('NewsRodeo.Infodisplay.Header'), 'text': i18next.t('NewsRodeo.Infodisplay.Entry2') },
        { 'path': '/images/references/RiedBusterminal01_1400x791.webp', 'header': i18next.t('NewsRodeo.Infodisplay.Header'), 'text': i18next.t('NewsRodeo.Infodisplay.Entry3') }
    ];
    return (
        <div className='App-references-webapp'>
            <Carousel className='escape' aspectRatio={0.5625} src={carouselSrc} />
        </div>
    );
}

function InteraktivesInfosystemSection() {
    const carouselSrc = [
        { 'path': '/images/references/Varena_1400x791.webp', 'header': i18next.t('NewsRodeo.Interactive.Header'), 'text': i18next.t('NewsRodeo.Interactive.Entry1') },
        { 'path': '/images/references/Raikka_InteraktivesInfosystem_1400x791.webp', 'header': i18next.t('NewsRodeo.Interactive.Header'), 'text': i18next.t('NewsRodeo.Interactive.Entry2') }
    ];
    return (
        <div className='App-references-webapp'>
            <Carousel className='escape' aspectRatio={0.5625} src={carouselSrc} />
        </div>
    );
}

function RaumUndTuerschilderSection() {
    const carouselSrc = [
        { 'path': '/images/references/PostSVAugsburg5_1400x791.webp', 'header': i18next.t('NewsRodeo.Doordisplay.Header'), 'text': i18next.t('NewsRodeo.Doordisplay.Entry1') }
    ];
    return (
        <div className='App-references-webapp'>
            <Carousel className='escape' aspectRatio={0.5625} src={carouselSrc} />
        </div>
    );
}

function MobileAppsSection() {
    const carouselSrc = [
        { 'path': '/images/references/Steigein_FahrApp_1400x791.webp', 'header': i18next.t('NewsRodeo.MobileApps.Header'), 'text': i18next.t('NewsRodeo.MobileApps.Entry1') },
        { 'path': '/images/references/Bmvit_AD_1400x791.webp', 'header': i18next.t('NewsRodeo.MobileApps.Header'), 'text': i18next.t('NewsRodeo.MobileApps.Entry2') },
        { 'path': '/images/references/Bmvit_AD_1400x791.webp', 'header': i18next.t('NewsRodeo.MobileApps.Header'), 'text': i18next.t('NewsRodeo.MobileApps.Entry3') },
        { 'path': '/images/references/Bmvit_AD_1400x791.webp', 'header': i18next.t('NewsRodeo.MobileApps.Header'), 'text': i18next.t('NewsRodeo.MobileApps.Entry4') }
    ];
    return (
        <div className='App-references-webapp'>
            <Carousel className='escape' aspectRatio={0.5625} src={carouselSrc} />
        </div>
    );
}

function IhrVorteilSection() {
    const listSrc = [
        { 'path': icon_cursor, 'header': i18next.t('NewsRodeo.Advantage.Useability.Header'), 'text': i18next.t('NewsRodeo.Advantage.Useability.Text') },
        { 'path': icon_dots, 'header': i18next.t('NewsRodeo.Advantage.Flexible.Header'), 'text': i18next.t('NewsRodeo.Advantage.Flexible.Text') },
        { 'path': icon_euro, 'header': i18next.t('NewsRodeo.Advantage.Cheap.Header'), 'text': i18next.t('NewsRodeo.Advantage.Cheap.Text') },
        { 'path': icon_thumbsup, 'header': i18next.t('NewsRodeo.Advantage.Stable.Header'), 'text': i18next.t('NewsRodeo.Advantage.Stable.Text') }
    ];
    return (
        <div className='NewsRodeo-ihr-vorteil'>
            <List header={i18next.t('NewsRodeo.Advantage.Header')} src={listSrc} />
        </div>
    );
}

function UnserAngebot() {
    const listSrc = [
        { 'path': icon_konzept, 'header': i18next.t('NewsRodeo.Offer.Concept.Header'), 'text': i18next.t('NewsRodeo.Offer.Concept.Text') },
        { 'path': icon_hardware, 'header': i18next.t('NewsRodeo.Offer.Hardware.Header'), 'text': i18next.t('NewsRodeo.Offer.Hardware.Text') },
        { 'path': icon_software, 'header':  i18next.t('NewsRodeo.Offer.Software.Header'), 'text': i18next.t('NewsRodeo.Offer.Software.Text') },
        { 'path': icon_grafik, 'header': i18next.t('NewsRodeo.Offer.Graphic.Header'), 'text': i18next.t('NewsRodeo.Offer.Graphic.Text') },
        { 'path': icon_wartung, 'header':  i18next.t('NewsRodeo.Offer.Maintenance.Header'), 'text': i18next.t('NewsRodeo.Offer.Maintenance.Text') },
        { 'path': icon_support, 'header':  i18next.t('NewsRodeo.Offer.Support.Header'), 'text': i18next.t('NewsRodeo.Offer.Support.Text') }
    ];
    return (
        <div className='NewsRodeo-unser-angebot'>
            <List header={i18next.t('NewsRodeo.Offer.Header')} src={listSrc} />
            <div className="NewsRodeo-unser-angebot-button-container">
                <ArrowButton className="ArrowButton-contact" text={i18next.t('NewsRodeo.Offer.Contact')} navigateto="/contact" />
            </div>
        </div>
    );
}

function WirGarantieren() {
    const listSrc = [
        { 'path': icon_cursor, 'header': i18next.t('NewsRodeo.PassengerInfo.Advantage.Interfaces.Header'), 'text': i18next.t('NewsRodeo.PassengerInfo.Advantage.Interfaces.Text') },
        { 'path': icon_dots, 'header': i18next.t('NewsRodeo.PassengerInfo.Advantage.Performance.Header'), 'text': i18next.t('NewsRodeo.PassengerInfo.Advantage.Performance.Text') },
        { 'path': icon_thumbsup, 'header': i18next.t('NewsRodeo.PassengerInfo.Advantage.Downtime.Header'), 'text': i18next.t('NewsRodeo.PassengerInfo.Advantage.Downtime.Text') },
        { 'path': icon_euro, 'header': i18next.t('NewsRodeo.PassengerInfo.Advantage.Caching.Header'), 'text': i18next.t('NewsRodeo.PassengerInfo.Advantage.Caching.Text') }
    ];
    return (
        <div className='NewsRodeo-wir-garantieren'>
            <List header={i18next.t('NewsRodeo.PassengerInfo.Advantage.Header')} src={listSrc} />
        </div>
    );
}

function Hardware() {
    const carouselSrc = [
        { 'path': 'images/newsrodeo/NR-PI-StFlorian.svg' },
        { 'path': 'images/newsrodeo/NR-PI-StFlorian.svg' }
    ];

    return (
        <div className='NewsRodeo-hardware'>
            <TextSection title={i18next.t('NewsRodeo.PassengerInfo.Hardware.Part1.Header')} text={i18next.t('NewsRodeo.PassengerInfo.Hardware.Part1.Text')} />
            <Carousel className='NewsRodeo-hardware-carousel' aspectRatio={0.5625} src={carouselSrc} />
            <TextSection text={i18next.t('NewsRodeo.PassengerInfo.Hardware.Part2.Text')} />
        </div>
    );
}