import React from 'react';
import PropTypes from 'prop-types';
import './TextSection.css'
import { createMarkup } from '../MarkupHelper';

export default class TextSection extends React.Component {
    render() {
        return (
            <div className='Text-section-container'>
                <h2 className='Text-section-title eurostile-condensed-heavy' dangerouslySetInnerHTML={createMarkup(this.props.title)}></h2>
                <div className='Text-section-subtitle eurostile-condensed-heavy' dangerouslySetInnerHTML={createMarkup(this.props.subtitle)}></div>
                <div className='Text-section-content' dangerouslySetInnerHTML={createMarkup(this.props.text)}></div>
            </div>
        );
    }
}

TextSection.defaultProps = {
    title: '',
    subtitle: '',
    text: '',
};

TextSection.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
}