import React from "react";
import PropTypes from "prop-types";
import { scrollTo, getLocationHashId } from "../App";
import { withTranslation } from "react-i18next";
import BookishContainer from "../component/BookishContainer";
import Footer from "../component/Footer";
import HomeIntro from "./HomeIntro";
import HomeServices from "./HomeServices";
import HomeProducts from "./HomeProducts";
import HomeReferences from "./HomeReferences";
import HomeServiceProvider from "./HomeServiceProvider";
import "./Home.css";
import HomeServiceImportant from "./HomeServiceImportant";

class Home extends React.Component {
    componentDidMount = () => {
        if (scrollTo(this.props.scrollto) === false) {
            if (scrollTo(getLocationHashId()) === false) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        }
    };

    componentDidUpdate = () => {
        scrollTo(this.props.scrollto);
    };

    render() {
        return (
            <div className="App-home">
                {/* Intro */}
                <BookishContainer
                    id="intro"
                    backgroundColor="#ffffff"
                    topLeftBorderBackgroundColor="#000000"
                >
                    <HomeIntro />
                </BookishContainer>
                {/* Service Provider */}
                <BookishContainer
                    id="service-provider"
                    backgroundColor="#f7a823"
                    topLeftBorderBackgroundColor="#FFFFFF"
                >
                    <HomeServiceProvider />
                </BookishContainer>
                {/* Services */}
                <BookishContainer
                    id="services-important"
                    backgroundColor="#000000"
                    bottomRightBorderBackgroundColor="#95a6b1"
                >
                    <HomeServiceImportant />
                </BookishContainer>
                {/* Products */}
                <BookishContainer
                    id="products"
                    header={this.props.t("Common.Products")}
                    backgroundColor="#95a6b1"
                    topLeftBorderBackgroundColor="#000000"
                    bottomRightBorderBackgroundColor="#b5c1c9"
                >
                    <HomeProducts />
                </BookishContainer>
                {/* ServicesBig */}
                <BookishContainer
                    id="services"
                    header={this.props.t("Common.Services")}
                    backgroundColor="#b5c1c9"
                    topLeftBorderBackgroundColor="#95a6b1"
                    bottomRightBorderBackgroundColor="#D5DBE0"
                >
                    <HomeServices />
                </BookishContainer>
                {/* References */}
                <BookishContainer
                    id="references"
                    header={this.props.t("Common.Customers")}
                    backgroundColor="#D5DBE0"
                    topLeftBorderBackgroundColor="#B5C1C9"
                    bottomRightBorderBackgroundColor="#000000"
                >
                    <HomeReferences />
                </BookishContainer>
                {/* Contact */}
                <BookishContainer
                    id="contact"
                    header={this.props.t("Common.Contact")}
                    textColor="#FFFFFF"
                    backgroundColor="#000000"
                    topLeftBorderBackgroundColor="#d5dbe0"
                >
                    <Footer hasGoogleMaps={true} />
                </BookishContainer>
            </div>
        );
    }
}

export default withTranslation()(Home);

Home.defaultProps = {
    scrollto: "",
};

Home.propTypes = {
    scrollto: PropTypes.string,
};
