import React from 'react';
import { withTranslation } from 'react-i18next';
import './Funktionsweise.css';
import lottie_funktionsweise from '../media/lottie_newsrodeo_funktionsweise.json';
import { Player } from '@lottiefiles/react-lottie-player';
import TextSection from '../component/TextSection.js';
import TileList from '../component/TileList.js';
import i18next from 'i18next';

class Funktionsweise extends React.Component {
    render() {
        return (
            <div className='Funktionsweise-container'>
                <div className='Funktionsweise-animiation-container'>
                    <FunktionsweiseAnimation />
                </div>
                <div className='Newsrodeo-verwaltungssoftware-container'>
                    <NewsRodeoVerwaltungsSoftware /> 
                </div>
                <div className='Newsrodeo-funktionsumfang-desktop-container'>
                    <Funktionsumfang />
                </div>
                <div className='Newsrodeo-cloud-player-container'>
                    <NewsRodeoCloudPlayer />
                </div>
                <div className='Newsrodeo-description-container'>
                    <NewsRodeoDescription />
                </div>
                <div className='Newsrodeo-funktionsumfang-mobile-container'>
                    <Funktionsumfang />
                </div>
            </div>      
        );
    }
}

export default withTranslation()(Funktionsweise);

function ImageSection(props){
    return (
        <div className='Newsrodeo-image-section'>
            <picture>
                <source media='(min-width: 1140px)' srcSet={props.pathDesktop} alt={props.altText} />
                <img src={props.pathMobile} alt={props.altText} />
            </picture>
        </div>
    );
}

function FunktionsweiseAnimation() {
    return (
        <div>
            <div className='Funktionsweise-headline'>
                <h1 className='eurostile-condensed-heavy'>{i18next.t('NewsRodeo.Functionality.Header')}</h1>
            </div>
            <div className='Funktionsweise-animation'>
                <Player autoplay loop src={lottie_funktionsweise} />
            </div>
        </div>
    );
}

function NewsRodeoVerwaltungsSoftware() {
    return (
        <div className='Newsrodeo-verwaltungssoftware'>
            <TextSection title={i18next.t('NewsRodeo.Functionality.Software.Header')} subtitle={i18next.t('NewsRodeo.Functionality.Software.SubTitle')} text={i18next.t('NewsRodeo.Functionality.Software.Text')} />
            <ImageSection pathMobile='/images/newsrodeo/NR_software_laptop_mobile.svg' pathDesktop='/images/newsrodeo/NR_software_laptop_desktop.svg' altText='NR_software_laptop' />                       
        </div>
    );
}

function NewsRodeoCloudPlayer() {
    return (
        <div className='Newsrodeo-cloud-player'>
            <div className='Text-section'>
                <TextSection title={i18next.t('NewsRodeo.Functionality.Cloud.Header')} subtitle={i18next.t('NewsRodeo.Functionality.Cloud.SubTitle')} text={i18next.t('NewsRodeo.Functionality.Cloud.Text')} />
                <TextSection title={i18next.t('NewsRodeo.Functionality.Player.Header')} subtitle={i18next.t('NewsRodeo.Functionality.Player.SubTitle')} text={i18next.t('NewsRodeo.Functionality.Player.Text')} />
            </div>
            <ImageSection pathMobile='/images/newsrodeo/ScreensaverStele.svg' altText='ScreensaverStele' />
        </div>
    );
}

function NewsRodeoDescription(){
    return (
        <div className='Newsrodeo-description' dangerouslySetInnerHTML={{ __html: i18next.t('NewsRodeo.Functionality.Description')}}></div>
    );   
}

function Funktionsumfang () {
    var tileSrc = [
        {'title': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Library.Header'), 'text': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Library.Text')},
        {'title': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Planing.Header'), 'text': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Planing.Text')},
        {'title': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Calender.Header'), 'text': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Calender.Text')},
        {'title': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Repeat.Header'), 'text': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Repeat.Text')},
        {'title': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Surveillance.Header'), 'text': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Surveillance.Text')},
        {'title': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Templates.Header'), 'text': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Templates.Text')},
        {'title': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Editor.Header'), 'text': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Editor.Text')},
        {'title': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Effects.Header'), 'text': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Effects.Text')},
        {'title': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Formats.Header'), 'text': i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Formats.Text')},
    ]

    return (
        <div className='Functionality-section-container'>
            <div className='Functionality-headline'>
                <h1 className='eurostile-condensed-heavy'>{i18next.t('NewsRodeo.Functionality.RangeOfFunctions.Header')}</h1>
            </div>
            <TileList src={tileSrc} />
        </div>
    );    
}