import React from 'react';
import { withTranslation } from 'react-i18next';
import './HomeServiceImportant.css';
import {createMarkup} from '../MarkupHelper';

class HomeServiceImportant extends React.Component {
    render() {
        return (
            <div className="App-home-services-important">
                <strong dangerouslySetInnerHTML={createMarkup(this.props.t("HomeServiceImportant.Header"))}></strong>
                <p dangerouslySetInnerHTML={createMarkup(this.props.t("HomeServiceImportant.Text"))}></p>
            </div>
        );
    }
}

export default withTranslation()(HomeServiceImportant);