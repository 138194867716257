import React from 'react';
import { withTranslation } from 'react-i18next';
import './IntroText.css';
import PropTypes from 'prop-types';


class IntroText extends React.Component {
    render() {
        return (
        <div className='Intro-text-container'>
            <div dangerouslySetInnerHTML={{ __html: this.props.text}} />
            <div className='Intro-text-header'>
                <span className='eurostile-condensed-heavy'>
                    <div dangerouslySetInnerHTML={{ __html: this.props.headerText}} />
                </span>
            </div>
        </div>
        );
    }
}

export default withTranslation()(IntroText);

IntroText.defaultProps = {
    text: '',
    headerText: ''
};

IntroText.propTypes = {
    text: PropTypes.string,
    headerText: PropTypes.string
}