import React from 'react';
import './Lizenzen.css';

export default class Lizenzen extends React.Component {

    componentDidMount() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <div className="App-lizenzen">
                <h2>NewsRodeo Lizenzvereinbarung</h2><br />
                <h4>1. Allgemeines</h4>
                <p>
                    Allen Leistungen und Lieferungen des Auftragnehmers liegen die nachstehenden Allgemeinen Vertragsbedingungen der POS.Screen GmbH (kurz POS) zugrunde. Ergänzende oder abweichende Vereinbarungen, wie insbesondere widersprechende Geschäftsbedingungen des Auftraggebers, bedürfen zu Ihrer Rechtswirksamkeit der ausdrücklichen schriftlichen Zustimmung des Auftragnehmers.
                </p>
                <br />
                <br />
                <h4>2. Nutzungsrechte</h4>
                <p>                  
                    Der Auftragnehmer räumt dem Auftraggeber nach vollständiger Entrichtung des vereinbarten Entgelts das nicht ausschließliche, zeitlich begrenzte und auf einen Rechner bezogene Recht ein, die Software NewsRodeo zu nutzen. Vor der vollständigen Entrichtung des vereinbarten Entgelts kommt dem Auftraggeber kein Recht zur Nutzung der Software zu, es sei denn der Auftragnehmer erklärt ausdrücklich und schriftlich dazu seine Zustimmung.<br /><br />
                    Eine Weitergabe von Produkten des Auftragnehmers ist generell nur innerhalb des Unternehmens des Auftraggebers erlaubt. Der Auftraggeber ist daher auch nicht berechtigt Sublizenzen an Dritte zu vergeben oder die Software zu erweitern. Eine Vervielfältigung der Software und/oder Umgehung der Lizenzbestimmungen ist stets unzulässig.<br /><br />
                    An allen vom Auftragnehmer für den Auftraggeber erstellten Arbeitsergebnissen steht dem Aufraggeber das oben dargestellte Nutzungsrecht zu. <br /><br />
                    Sämtliche Immaterialgüterrechte, insbesondere Urheberrechte, an den zur Nutzung zur Verfügung gestellten Produkten verbleiben beim Auftragnehmer. <br /><br />
                    Eine Verpflichtung zur Herausgabe des Quellcodes oder sonstiger Unterlagen im Zusammenhang mit Produkten des Auftragnehmers ist in jedem Fall ausgeschlossen, ausgenommen davon ist eine eventuelle elektronische Beschreibung.<br /> <br />
                </p>
                <br />
                <br />
                <h4>3. Voraussetzungen für die Nutzung - Serverbetrieb</h4>
                <p>
                    Die Nutzung der Software setzt eine Verbindung zu den Servern der Auftragsnehmer bzw. zu den Servern der POS voraus. Die Software protokolliert Vorgänge auf den Servern automatisch. Die Nutzung des Softwareproduktes setzte eine Internetverbindung voraus. Die Verbindung zum Internet kann entweder über eine direkte Leitung (RJ 45) oder auch über ein W-LAN hergestellt werden. Es muss die Verfügbarkeit des Internets vor der Installation von dem Kunden geprüft werden. Wir weisen darauf hin, dass die Sicherheit einer W-LAN Verbindung nicht so hoch ist wie die einer direkten Leitung. Die genauen Informationen für den Netzwerkzugang müssen vor der Montage an den Auftragnehmer übermittelt werden, spätestens einen Tag vor der Montage. (IP-Adresse, Subnet Maske, Gateway, Proxy, DNS-Server).<br /><br />
                    Die Softwarenutzung für den Client ist nur von dem Rechner möglich, an dem die Erstanmeldung zum Server erfolgt. 
                </p>
                <h4>Laufzeit und Kündigung des Serverbetriebes</h4>
                <p>
                    Der Serverbetrieb, sofern nicht der Server des Kunden genutzt wird, wird jährlich im Voraus für das nächste Jahr verrechnet. Und ist nach Erhalt der Rechnung ohne Abzug fällig. <br /><br />
                    Im ersten Vertragsjahr (Rumpfjahr) wird die Gebühr pro Monat (1/12) anteilig für das verbleibende Kalenderjahr berechnet. Der Serverbetrieb wird zunächst für den Rest des Kalenderjahres abgeschlossen und verlängert sich automatisch um ein Jahr, wenn er nicht unter Einhaltung einer zweimonatigen Kündigungsfrist zum Ablauf des Kalenderjahres schriftlich, eingeschrieben gekündigt wird.<br /><br />
                    POS ist berechtigt, spätestens 3 Monate vor Ablauf des laufenden Vertragsjahres die Gebühr für den Serverbetrieb für das folgende Vertragsjahr durch schriftliche Mitteilung dem Lizenznehmer gegenüber neu festzusetzen. Kündigt der Lizenznehmer daraufhin den Serverbetrieb nicht, so gilt für das neue Vertragsjahr die neue Serverbetrieb.
                </p>
                <br />
                <br />
                <h4>4. Verfügbarkeit</h4>
                <p>
                    Die Nutzung der Verwaltungssoftware NewsRodeo ist nur mit Benutzernamen und Passwort möglich. Die Nutzung der Player Software NewsRodeo (IPC) ist nur möglich mit erfolgter Registrierung und gültigem Wartungsvertrag.
                </p>
                <br />
                <br />
                <h4>5. Installation und Schulung</h4>
                <p>
                    Installation und Schulung können beim Auftragnehmer angefordert werden. Die Verrechnung erfolgt nach Aufwand; die Erbringung derartiger Leistungen erfolgt auf Basis der AGB der POS.Screen GmbH (www.posscreen.at).<br /><br />
                    Wenn vereinbart, ist eine elektronische Beschreibung der Produkte des Auftragnehmers samt deren Funktionalitäten im Lieferumfang enthalten.
                </p>
                <br />
                <br />
                <h4>6. Softwarepflege / Wartungsvertrag</h4>
                <p>
                    Der Auftragnehmer wird seine Software dem Stand der Technik entsprechend ausliefern, allfällige Fehler, die die Nutzung eines Produkts erheblich beeinträchtigen (Einschränkung der wesentlichen Funktionalitäten), bei einem gültigem Wartungsvertrag beheben, insbesondere in dem entsprechende Updates geliefert werden.<br /><br />
                    Ohne gültigem Wartungsvertrag ist die Aktualisierungspflicht gemäß Verbrauchergewährleistungsgesetz §7 ausgeschlossen. Dies gilt insbesondere, wenn die Player Hardware nicht vom Auftragnehmer (POS.Screen) zur Verfügung gestellt wird.
                </p>
                <h4>Wartungsvetrag</h4>
                <p>
                    Die Sicherung des Programmstandes, eine fortdauernde Betreuung und die Überlassung von aktualisierten Programmständen wird durch einen eigenen Wartungsvertrag gesichert.<br /><br />
                    Software-Wartungsverträge haben die Sicherung eines Programmstandes, eine fortdauernde Betreuung und die Überlassung von aktualisierten Programmständen, zum Beispiel durch gesetzliche Änderungen zum Ziel, nicht jedoch technische Änderungen, Anpassungen aufgrund von notwendigem, kostenpflichtigem Betriebssystemwechsel, Sonderanpassungen und Ergänzungswünsche des Lizenznehmers. Diese werden gesondert abgewickelt und nach Aufwand berechnet. POS wird die Software nach bestem Wissen und Gewissen pflegen und zur Zufriedenheit der Anwender tätig sein. Eine Haftung ist jedoch ausgeschlossen. <br /><br />
                    Gegenstand der Software-Wartung ist die jeweils letzte vom Auftragnehmer freigegebene und dem Auftraggeber überlassene Programmversion des/r Softwareprogramms/e. <br /><br />
                    Die Benachrichtigung über Updates erfolgt beim Einloggen über das Verwaltungssystem, die Einspielung muss selbst durchgeführt werden. Zu einer darüberhinausgehenden Pflege der Software, welcher Art auch immer, ist der Auftragnehmer nicht verpflichtet.<br /><br />
                    Funktionserweiternde Updates sind kostenpflichtig und können auf Basis dieser AGB vom Auftragnehmer bezogen werden.
                </p>
                <p>
                    Durch den späteren Erwerb von
                    <ul> 
                        <li>Upgrades, d.h. kostenpflichtige Programmwechsel zu einer höheren Programmvariante (z.B. von Basic zu Standard) oder </li>
                        <li>Zusatzmodulen, d.h. kostenpflichtige Programmerweiterungen, die zusätzlich zur Basisversion erworben werden können, </li>
                    </ul>
                    wird der Umfang des Software-Wartungsvertrages automatisch entsprechend erweitert. 
                </p>
                <p>
                    POS verpflichtet sich, während der Vertragsdauer folgende Wartungsleistungen zu erbringen:
                    <ul>
                        <li>Angemessene telefonische Beratung des Lizenznehmers in Fragen der Bedienung der oben als Wartungsgegenstand gekennzeichneten Programme. Dies umfasst keine Bedienerschulungen, diese können kostenpflichtig zusätzlich gebucht werden.</li>
                        <li>Beseitigung von rekonstruierbaren Programmfehlern. </li>
                        <li>POS wird den Lizenznehmer über neue Versionen, die Upgrades darstellen, informieren. Als kostenpflichtige Upgrade wird gewertet, wenn insbesondere Funktionserweiterungen in das Produkt integriert wurden. </li>
                        <li>Die vorzunehmenden Wartungsleistungen werden baldmöglichst innerhalb der Geschäftszeiten von POS durchgeführt. Die Geschäftszeiten sind (ausgenommen Feiertage): </li>
                        <ul>
                            <li>Montag bis Donnerstag 8.30 bis 12.00 Uhr und 13.00 bis 17.00 Uhr, </li>
                            <li>Freitag 8.30 bis 12.00 Uhr.</li>
                        </ul>
                    </ul>
                </p>
                <p>
                    Dem Lizenznehmer wird ein Wartungsrecht wie oben beschrieben eingeräumt. Eine Weiterübertragung an Dritte ist unzulässig. Der Lizenznehmer kann unter der oben genannten Telefon- bzw. Faxnummer oder schriftlich unter der oben genannten Adresse bzw. per E-Mail die Wartungsleistungen in Anspruch nehmen. Dabei ist vom Lizenznehmer die hier eingetragene Seriennummer mitzuteilen, ansonsten besteht kein Anspruch auf Leistungen. <br /><br />
                    Die Wartungsgebühr ist wertgesichert und jährlich im Vorhinein zu entrichten.
                </p>
                <h4>Laufzeit und Kündigung der Wartung</h4>
                <p>
                    Die Wartung beginnt mit der Zahlungseingang der ersten Rechnung „Softwarewartung“ oder „Softwarewartung und Serverbetrieb“.<br /><br />
                    Der Zahlungseingang gilt als Zustimmung zur Nutzungs- und Wartungsbestimmungen, sowie allfälliger Änderungen oder Anpassungen. <br /><br />
                    Das Wartungsentgelt wird am Beginn jedes Vertragsjahres in Rechnung gestellt und ist nach Erhalt der Rechnung ohne Abzug sofort fällig. Wird der Vertragsbeginn nicht besonders vereinbart, so beginnt die Wartung mit Abschluss des Wartungsvertrages, frühestens jedoch mit Lieferung der zu wartenden Software.<br /><br />
                    Im ersten Vertragsjahr (Rumpfjahr) wird die Gebühr pro Monat (1/12) anteilig für das verbleibende Kalenderjahr berechnet. Der Wartungsvertrag wird zunächst für den Rest des Kalenderjahres abgeschlossen und verlängert sich automatisch um ein Jahr, wenn er nicht unter Einhaltung einer zweimonatigen Kündigungsfrist zum Ablauf des Kalenderjahres schriftlich, eingeschrieben gekündigt wird.<br /><br />
                    POS ist berechtigt, spätestens 3 Monate vor Ablauf des laufenden Vertragsjahres die Wartungsgebühr für das folgende Vertragsjahr durch schriftliche Mitteilung dem Lizenznehmer gegenüber neu festzusetzen. Kündigt der Lizenznehmer daraufhin den Wartungsvertrag nicht, so gilt für das neue Vertragsjahr die neue Wartungsgebühr.
                </p>
                <h4>Einschränkungen der Wartungsleistungen</h4>
                <p>
                    Nicht im Vertragsumfang enthalten ist die Beseitigung von Störungen oder Schäden aufgrund unsachgemäßer Behandlung oder sonstiger äußerer Einwirkungen, die nicht von POS zu vertreten sind. Leistungen, die von POS zur Beseitigung solcher Störungen erbracht werden, werden zu den jeweils gültigen Preisen gesondert in Rechnung gestellt. <br /><br />
                    Eingriffe an der Software oder an den von der Software verwalteten Daten, durch den Lizenznehmer oder Dritte, entbinden POS von den Verpflichtungen dieses Abkommens. 
                </p>
                <br />
                <br />
                <h4>7. Gewährleistung</h4>
                <p>
                    Der Auftragnehmer garantiert, dass die vom Auftragnehmer erbrachten Leistungen frei von Schutzrechten Dritter sind, die die Nutzung durch den Auftraggeber ausschließen oder beeinträchtigen. Der Auftragnehmer leistet Gewähr, berechtigt zu sein, ein Nutzungsrecht gem. Punkt 2 einzuräumen.<br /><br />
                    Der Auftragnehmer gewährleistet für einen Zeitraum von 6 Monaten ab Lieferung bzw. Leistung, dass die vom Auftragnehmer erbrachten Lieferungen und Leistungen im Wesentlichen den in den ausgehändigten Dokumentationsunterlagen festgelegten Spezifikationen entsprechen. Sollte der Auftraggeber den Auftragnehmer über eine wesentliche Abweichung informieren, so wird der Auftragnehmer auf eigene Kosten alle notwendigen Änderungen oder Verbesserungen vornehmen, um diese Abweichung zu beheben.<br /><br />
                    Abgesehen von den in diesen AGB ausdrücklich erwähnten Fällen, übernimmt der Auftragnehmer keinerlei Gewähr, weder ausdrücklich noch stillschweigend, ausgenommen jene, dass der in den einschlägigen Dokumentationsunterlagen und Produktinformationen enthaltene Gebrauch der Produkte des Auftragnehmers sichergestellt ist.<br /><br />
                    Eventuelle Gewährleistungsansprüche erlöschen automatisch, wenn ohne ausdrückliche Zustimmung des Auftragnehmers durch den Auftraggeber oder durch Dritte Eingriffe an der Software oder sonstigen Produkten des Auftragnehmers vorgenommen wurden.
                </p>
                <br />
                <br />
                <h4>8. Haftung</h4>
                <p>
                    Die Rechte an allen Daten welche für das System zur Verfügung gestellt werden, müssen beim Auftraggeber vorhanden sein inklusive der Weitergabe an Dritte. <br /><br />
                    Der Auftragnehmer übernimmt keine Verantwortung für den Inhalt, die Richtigkeit und die Form einzelner eingestellter Beiträge bzw. verarbeiteter Inhalte. Die in den Beiträgen der Nutzer getroffenen Aussagen stellen keine Meinungsäußerungen oder Tatsachenbehauptungen dar, mit denen sich der Auftragnehmer / Betreiber dieses Systems identifiziert oder solidarisiert. Der Auftragnehmer /Betreiber distanziert sich von den inhaltlichen Aussagen in diesem Forum und ist für diese nicht verantwortlich.<br /><br />
                    Sollte der Auftragnehmer von Dritten in Anspruch genommen werden, so hält ihn der Auftraggeber schad- und klaglos. <br /><br />
                    Schadensersatzansprüche an POS sind ausgeschlossen, soweit nicht insbesondere in Fällen des Vorsatzes oder der groben Fahrlässigkeit zwingend gehaftet wird. Eine Haftung für Folgeschäden, gleich welcher Art, ist ausgeschlossen. <br /><br />
                    Eine Schadensersatzpflicht ist in jedem Fall begrenzt durch die Höhe der vereinbarten Wartungsgebühr pro Vertragsjahr.
                </p>
            </div>
        );
    }
}