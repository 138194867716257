import React from 'react';
import PropTypes from 'prop-types';
import './TileList.css';

export default class TileList extends React.Component {
    render() {
        const items = [];
        const length = this.countItems()

        for (let i = 0; i < length; i++) {
            const title = this.getTitle(i);
            const text = this.getText(i);
            const itemContainer = (
                <div className='Tile-item' key={'tile-item-' + i}>
                    <b>{title}</b>
                    <div>{text}</div>
                </div>
            );
            items.push(itemContainer);
        }

        return (
            <div className='TileList-container'>
                <div className='TileList-items-container'>
                    {items.map((Item, i) => Item)}
                </div>
            </div>
        );
    };

    getItem(index) {
        let count = this.countItems();
        if (count > 0 && count > index && index >= 0) {
            return this.props.src[index];
        }
        return undefined;
    }

    getTitle(index) {
        let item = this.getItem(index);
        if (item && item.title) {
            return item.title;
        }
        return undefined;
    }

    getText(index) {
        let item = this.getItem(index);
        if (item && item.text) {
            return item.text;
        }
        return undefined;
    }

    countItems() {
        if (this.props.src && this.props.src.length > 0) {
            return this.props.src.length;
        }
        return 0;
    }
}

TileList.defaultProps = {
    src: []
};

TileList.propTypes = {
    src: PropTypes.array
}