import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './FlatButton.css';

class FlatButton extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (this.props.navigateto && this.props.navigateto.length > 0) {
            this.props.navigate(this.props.navigateto);
        }
        if (this.props.onClick && typeof this.props.onClick === 'function') {
            this.props.onClick();
        }
    }

    render() {
        let extraClassName = '';
        if (this.props.className && this.props.className.length > 0) {
            extraClassName = ' ' + this.props.className;
        }
        return (
            <a className={`FlatButton${extraClassName}`} href={this.props.navigateto}><span className='FlatButtonText'>{this.props.text}</span></a>
        );
    }
}

FlatButton.defaultProps = {
    text: '',
    navigateto: ''
};

FlatButton.propTypes = {
    text: PropTypes.string,
    navigateto: PropTypes.string
}

export default function FlatButtonUseNavigate(props) {
    const navigate = useNavigate();
    return <FlatButton {...props} navigate={navigate} />
}