import React from 'react';
import PropTypes from 'prop-types';
import './List.css';

export default class List extends React.Component {
    render() {


        const items = [];
        const length = this.countItems()

        for (let i = 0; i < length; i++) {
            const imagePath = this.getPath(i);
            const imageAltText = this.getNameFromPath(imagePath);
            const itemHeader = this.getHeader(i);
            const itemText = this.getText(i);

            const itemContainer = (
            <div className='List-item-container' key={'list-item-' + i}>
                <img src={imagePath} alt={imageAltText} />
                <h2 className='eurostile-condensed-heavy'>{itemHeader}</h2>
                <span className='eurostile-condesed'>{itemText}</span>
            </div>);
            items.push(itemContainer);
        }

        return (
            <div className='List-container'>
                <h1 className='eurostile-condensed-heavy'>{this.props.header}</h1>
                <div className='List-items-container'>
                    {items.map((Item, i) => Item)}
                </div>
            </div>);

    }


    countItems() {
        if (this.props.src && this.props.src.length > 0) {
            return this.props.src.length;
        }
        return 0;
    }

    getItem(index) {
        let count = this.countItems();
        if (count > 0 && count > index && index >= 0) {
            return this.props.src[index];
        }
        return undefined;
    }

    hasItem(index) {
        let item = this.getItem(index);
        if (item) {
            return true;
        }
        return false;
    }

    getPath(index) {
        let item = this.getItem(index);
        if (item && item.path) {
            return item.path;
        }
        return undefined;
    }

    getHeader(index) {
        let item = this.getItem(index);
        if (item && item.header) {
            return item.header;
        }
        return undefined;
    }

    getText(index) {
        let item = this.getItem(index);
        if (item && item.text) {
            return item.text;
        }
        return undefined;
    }

    getNameFromPath(path) {
        try {
            if (path && path.length > 0) {
                return path.split('/').pop().split('.').shift();
            }
        }
        catch (e) { }
        return '';
    }
}

List.defaultProps = {
    header: '',
    src: []

};

List.propTypes = {
    header: PropTypes.string,
    src: PropTypes.array
}