import React from 'react';
import { withTranslation } from 'react-i18next';
import FlatButton from '../component/FlatButton'
import './HomeServices.css';

class HomeServices extends React.Component {
    render() {
        return (
            <div className="App-home-services">
                {/* If string contains html-tags, translate the string via dangerouslySetInnerHtml */}
                
                <div dangerouslySetInnerHTML={{ __html: this.props.t('HomeService.Header')}}></div>

                <div className="App-home-services-area App-home-services-area-1 right">
                    <div className="App-home-services-area-bubble"></div>
                    <div className="App-home-services-area-border"></div>
                    <div className="App-home-services-area-inner">
                        <div className="App-home-services-area-inner-header eurostile-condensed-heavy">{this.props.t('HomeService.Consulting.Title')}</div>
                        <div className="App-home-services-area-inner-text">{this.props.t('HomeService.Consulting.SubTitle')}</div>
                    </div>
                </div>
                <div className="App-home-services-area App-home-services-area-2 left">
                    <div className="App-home-services-area-bubble"></div>
                    <div className="App-home-services-area-border"></div>
                    <div className="App-home-services-area-inner">
                        <div className="App-home-services-area-inner-header eurostile-condensed-heavy">{this.props.t('HomeService.Support.Title')}</div>
                        <div className="App-home-services-area-inner-text">{this.props.t('HomeService.Support.SubTitle')}</div>
                    </div>
                </div>
                <div className="App-home-services-area App-home-services-area-3 right">
                    <div className="App-home-services-area-bubble"></div>
                    <div className="App-home-services-area-border"></div>
                    <div className="App-home-services-area-inner">
                        <div className="App-home-services-area-inner-header eurostile-condensed-heavy">{this.props.t('HomeService.GraphicDesign.Title')}</div>
                        <div className="App-home-services-area-inner-text">{this.props.t('HomeService.GraphicDesign.SubTitle')}</div>
                    </div>
                </div>
                <div className="App-home-services-area App-home-services-area-4 left">
                    <div dangerouslySetInnerHTML={{ __html: this.props.t('HomeService.Footer')}}></div>
                </div>               
                <div style={{textAlign: 'center'}}>
                    <FlatButton className="ArrowButton-more" text={this.props.t("HomeService.ContactUs")} navigateto="mailto:office@posscreen.at" />
                </div>
                
            </div>
        );
    }
}

export default withTranslation()(HomeServices);