import React from 'react';
import './Impressum.css';

export default class Impressum extends React.Component {

    componentDidMount() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <div className="App-impressum">
                <h2>Impressum</h2><br />
                <p>
                    <b>POS.SCREEN GmbH</b><br />
                    Jägerweg 4<br />
                    4600 Thalheim bei Wels<br />
                    Österreich<br /><br />
                    E-Mail: office@posscreen.at<br />
                    Tel: +43 (0) 72 42 / 25 20 50-25<br />
                    Fax: +43 (0) 72 42 / 25 20 50-94<br /><br />
                    Firmenbuchnummer: FN 310231y,  Landesgericht Wels<br />
                    UID-Nr.: ATU64168037<br />
                    Geschäfsführer: Bernhard Schleicher, Gregor Stritzinger
                    <br />
                </p>
                <a href="https://zertifikat.creditreform.at/anzeige_2021.php?code=xaQIuHGbBLFMa9WdLPnnytfB1eLk96SI&height=0" target="_blank" rel="noopener">
                <img src="https://zertifikat.creditreform.at/anzeige_2021.php?code=xaQIuHGbBLFMa9WdLPnnytfB1eLk96SI&height=1000" height="500px"/></a><br /><br /><br /><br />
                <h2>Allgemeine Geschäftsbedingungen</h2><br />
                <h4>1. Vertragsumfang und Gültigkeit</h4>
                <p>
                Alle Aufträge und Vereinbarungen sind nur dann rechtsverbindlich, wenn sie vom Auftragnehmer schriftlich und firmengemäß gezeichnet werden und verpflichten nur in dem in der Auftragsbestätigung angegebenen Umfang. Einkaufsbedingungen des Auftraggebers werden für das gegenständliche Rechtsgeschäft und die gesamte Geschäftsbeziehung hiermit ausgeschlossen. Angebote sind grundsätzlich freibleibend. Änderungen und Ergänzungen bedürfen der Schriftform; mündliche Zusagen bzw. Vereinbarungen sind ungültig, solange sie nicht schriftlich bestätigt sind. 
                <br/><br/>
                Sämtliche Verpflichtungen des Auftragsnehmers sind dann ausgesetzt, wenn und solange der Auftraggeber seinerseits mit Leistungen (insbesondere Zahlungen) in Verzug ist.
                </p>
                <br />
                <br />
                <h4>2. Leistung und Prüfung</h4>
                <h4>2.1 Mögliche Auftragsgegenstände umfassen:</h4>
                <p>                  
                    – Ausarbeitung von Organisationskonzepten<br />
                    – Global- und Detailanalysen<br />
                    – Erstellung von Individualprogrammen<br />
                    – Lieferung von Bibliotheks- (Standard-)Programmen<br />
                    – Erwerb von Nutzungsberechtigungen für Softwareprodukte<br />
                    – Erwerb von Werknutzungsbewilligungen<br />
                    – Mitwirkung bei der Inbetriebnahme (Umstellungsunterstützung)<br />
                    – Telefonische Beratung<br />
                    – Programmwartung<br />
                    – Erstellung von Programmträgern<br />
                    – Sonstige Dienstleistungen<br />
                    – Hardware Lieferungen

                </p>
                <h4>2.2 </h4>
                <p>
                    Die Ausarbeitung individueller Organisationskonzepte und Programme erfolgt nach Art und Umfang der vom Auftraggeber vollständig zur Verfügung gestellten, bindenden Informationen, Unterlagen und Hilfsmittel. Dazu zählen auch praxisgerechte Testdaten sowie Testmöglichkeiten in ausreichendem Ausmaß, die der Auftraggeber zeitgerecht, in der Normalarbeitszeit (Montag bis Donnerstag 8.30 bis 12.00 Uhr und 13.00 bis 17.00 Uhr, Freitag 8.30 bis 12.00 Uhr, ausgenommen Feiertage) und auf eigene Kosten zur Verfügung stellt. Wird vom Auftraggeber bereits auf der zum Test zur Verfügung gestellten Anlage im Echtbetrieb gearbeitet, liegt die Verantwortung für die Sicherung der Anlage und der Echtdaten beim Auftraggeber. Im Falle des Verlustes der Echtdaten oder einer Schädigung der Anlage wird jegliche Haftung ausgeschlossen.
                </p>
                <h4>2.3 </h4>
                <p>
                    Grundlage für die Erstellung von Individualprogrammen ist die schriftliche Leistungsbeschreibung, die der Auftragnehmer gegen Kostenberechnung aufgrund der ihm zur Verfügung gestellten Unterlagen und Informationen ausarbeitet bzw. der Auftraggeber zur Verfügung stellt. Diese Leistungsbeschreibung ist vom Auftraggeber auf Richtigkeit und Vollständigkeit zu überprüfen und mit seinem Zustimmungsvermerk zu versehen. Später auftretende Änderungswünsche können zu gesonderten Termin- und Preisvereinbarungen führen.
                </p>
                <h4>2.4 </h4>
                <p>
                    Individuell erstellte Software bzw. Programmadaptierungen bedürfen für das jeweils betroffene Programmpaket einer Programmabnahme spätestens vier Wochen ab Lieferung durch den Auftraggeber. Diese wird in einem Protokoll vom Auftraggeber bestätigt. (Prüfung auf Richtigkeit und Vollständigkeit anhand der vom Auftragnehmer akzeptierten Leistungsbeschreibung mittels der unter Punkt 2.2. angeführten zur Verfügung gestellten Testdaten). Lässt der Auftraggeber den Zeitraum von vier Wochen ohne Programmabnahme verstreichen, so gilt die gelieferte Software mit dem Enddatum des genannten Zeitraumes als abgenommen. Bei Einsatz der Software im Echtbetrieb durch den Auftraggeber gilt die Software jedenfalls als abgenommen. Etwaig auftretende Mängel, das sind Abweichungen von der schriftlich vereinbarten Leistungsbeschreibung, sind vom Auftraggeber ausreichend dokumentiert dem Auftragnehmer zu melden, der um raschest mögliche Mängelbehebung bemüht ist. Liegen schriftlich gemeldete, wesentliche Mängel vor, das heißt, dass der Echtbetrieb nicht begonnen oder fortgesetzt werden kann, so ist nach Mängelbehebung eine neuerliche Abnahme erforderlich. Der Auftraggeber ist nicht berechtigt, die Abnahme von Software wegen unwesentlicher Mängel abzulehnen.
                </p>
                <h4>2.5 </h4>
                <p>
                    Bei Bestellung von Bibliotheks-(Standard-)Programmen bestätigt der Auftraggeber mit der Bestellung die Kenntnis des Leistungsumfanges der bestellten Programme.
                </p>
                <h4>2.6 </h4>
                <p>
                    Sollte sich im Zuge der Arbeiten herausstellen, dass die Ausführung des Auftrages gemäß Leistungsbeschreibung technisch oder juristisch unmöglich ist, ist der Auftragnehmer verpflichtet, dies dem Auftraggeber sofort anzuzeigen. Ändert der Auftraggeber die Leistungsbeschreibung nicht dahingehend bzw. schafft die Voraussetzung, dass eine Ausführung möglich wird, kann der Auftragnehmer die Ausführung ablehnen. Ist die Unmöglichkeit der Ausführung die Folge eines Versäumnisses des Auftraggebers oder einer nachträglichen Änderung der Leistungsbeschreibung durch den Auftraggeber, ist der Auftragnehmer berechtigt, vom Auftrag zurückzutreten. Die bis dahin für die Tätigkeit des Auftragnehmers angefallenen Kosten und Spesen sowie allfällige Abbaukosten sind vom Auftraggeber zu ersetzen.
                </p>
                <h4>2.7 </h4>
                <p>
                    Ein Versand von Programmträgern, Dokumentationen und Leistungsbeschreibungen erfolgt auf Kosten und Gefahr des Auftraggebers. Darüber hinaus vom Auftraggeber gewünschte Schulung und Erklärungen werden gesondert in Rechnung gestellt. Versicherungen erfolgen nur auf Wunsch und Kosten des Auftraggebers.
                </p>
                <br />
                <br />
                <h4>3. Preise, Steuern und Gebühren</h4>
                <h4>3.1 </h4>
                <p>
                    Alle Preise verstehen sich in Euro ohne Umsatzsteuer. Sie gelten nur für den vorliegenden Auftrag. Die genannten Preise verstehen sich ab Geschäftssitz bzw. - Stelle des Auftragnehmers. Die Kosten von Programmträgern (z.B. CD’s, Magnetbänder, Magnetplatten, Floppy Disks, Streamer Tapes, Magnetbandkassetten usw.) sowie allfällige Vertragsgebühren werden gesondert in Rechnung gestellt.
                </p>
                <h4>3.2 </h4>
                <p>
                    Bei Bibliotheks- (Standard)-Programmen gelten die am Tag der Lieferung gültigen Listenpreise. Bei allen anderen Dienstleistungen (Organisationsberatung, Programmierung, Einschulung, Umstellungsunterstützung, telefonische Beratung, usw.) wird der Arbeitsaufwand zu den am Tag der Leistungserbringung gültigen Sätzen verrechnet. Abweichungen von einem dem Vertragspreis zugrundeliegenden Zeitaufwand, der nicht vom Auftragnehmer zu vertreten ist, werden nach tatsächlichem Anfall berechnet.
                </p>
                <h4>3.3 </h4>
                <p>
                    Die Kosten für Fahrt-, Tag- und Nächtigungsgelder werden dem Auftraggeber gesondert nach den jeweils gültigen Sätzen in Rechnung gestellt. Wegzeiten gelten als Arbeitszeit.
                </p>
                <br />
                <br />
                <h4>4. Liefertermin</h4>
                <h4>4.1 </h4>
                <p>
                    Der Auftragnehmer ist bestrebt, die vereinbarten Termine der Erfüllung (Fertigstellung) möglichst genau einzuhalten.
                </p>
                <h4>4.2 </h4>
                <p>
                    Die angestrebten Erfüllungstermine können nur dann eingehalten werden, wenn der Auftraggeber zu den vom Auftragnehmer angegebenen Terminen alle notwendigen Arbeiten und Unterlagen vollständig, insbesondere die von ihm akzeptierte Leistungsbeschreibung lt. Punkt 2.3., zur Verfügung stellt und seiner Mitwirkungsverpflichtung im erforderlichen Ausmaß nachkommt. Lieferverzögerungen und Kostenerhöhungen, die durch unrichtige, unvollständige oder nachträglich geänderte Angaben und Informationen bzw. zur Verfügung gestellte Unterlagen entstehen, sind vom Auftragnehmer nicht zu vertreten und können nicht zum Verzug des Auftragnehmers führen. Daraus resultierende Mehrkosten trägt der Auftraggeber.
                </p>
                <h4>4.3 </h4>
                <p>
                    Bei Aufträgen, die mehrere Einheiten bzw. Programme umfassen, ist der Auftragnehmer berechtigt, Teillieferungen durchzuführen bzw. Teilrechnungen zu legen.
                </p>
                <br />
                <br />
                <h4>5. Zahlung</h4>
                <h4>5.1 </h4>
                <p>
                    Die vom Auftragnehmer gelegten Rechnungen inklusive Umsatzsteuer sind bis spätestens 14 Tage ab Fakturenerhalt ohne jeden Abzug und spesenfrei zahlbar. Für Teilrechnungen gelten die für den Gesamtauftrag festgelegten Zahlungsbedingungenen analog.
                </p>
                <h4>5.2 </h4>
                <p>
                    Bei Aufträgen, die mehrere Einheiten (z.B. Programme und/oder Schulungen, Realisierungen in Teilschritten) umfassen, ist der Auftragnehmer berechtigt, nach Lieferung jeder einzelnen Einheit oder Leistung Rechnung zu legen.
                </p>
                <h4>5.3 </h4>
                <p>
                    Die Einhaltung der vereinbarten Zahlungstermine bildet eine wesentliche Bedingung für die Durchführung der Lieferung bzw. Vertragserfüllung durch den Auftragnehmer. Die Nichteinhaltung der vereinbarten Zahlungen berechtigt den Auftragnehmer, die laufenden Arbeiten einzustellen und vom Vertrag zurückzutreten. Alle damit verbundenen Kosten sowie der Gewinnentgang sind vom Auftraggeber zu tragen.
                    <br /><br />
                    Bei Zahlungsverzug werden Verzugszinsen im banküblichen Ausmaß verrechnet.  Bei Nichteinhaltung zweier Raten bei Teilzahlungen ist der Auftragsnehmer berechtigt, Terminverlust in Kraft treten zu lassen und übergebene Akzepte fällig zu stellen. 
                </p>
                <h4>5.4 </h4>
                <p>
                    Der Auftraggeber ist nicht berechtigt, Zahlungen wegen nicht vollständiger Gesamtlieferung, Garantie- oder Gewährleistungsansprüchen oder Bemängelungen zurück zu halten.
                </p>
                <br />
                <br />
                <h4>6. Urheberrecht und Nutzung</h4>
                <h4>6.1 </h4>
                <p>
                    Alle Urheberrechte an den vereinbarten Leistungen (Programme, Dokumentationen etc.) stehen dem Auftragnehmer bzw. dessen Lizenzgebern zu. Der Auftraggeber erhält ausschließlich das Recht, die Software nach Bezahlung des vereinbarten Entgelts einmal ausschließlich zu eigenen Zwecken, nur für die im Vertrag spezifizierte Hardware und im Ausmaß der erworbenen Anzahl der Lizenzen für die gleichzeitige Nutzung auf mehreren Arbeitsplätzen zu verwenden. <br /><br />
                    Durch den gegenständlichen Vertrag wird lediglich eine Werknutzungsbewilligung erworben. Eine Verbreitung durch den Auftraggeber ist gemäß Urheberrechtsgesetz ausgeschlossen. Durch die Mitwirkung des Auftraggebers bei der Herstellung der Software werden keine Rechte über die im gegenständlichen Vertrag festgelegte Nutzung hinaus erworben. Jede Verletzung der Urheberrechte des Auftragnehmers zieht Schadenersatzansprüche nach sich, wobei in einem solchen Fall volle Genugtuung zu leisten ist und ein Mäßigungsrecht ausgeschlossen ist. 
                </p>
                <h4>6.2 </h4>
                <p>
                    Die Anfertigung von Kopien für Archiv- und Datensicherungszwecke ist dem Auftraggeber unter der Bedingung gestattet, dass in der Software kein ausdrückliches Verbot des Lizenzgebers oder Dritter enthalten ist, und dass sämtliche Copyright- und Eigentumsvermerke in diese Kopien unverändert mit übertragen werden.
                </p>
                <h4>6.3 </h4>
                <p>
                    Sollte für die Herstellung von Interoperabilität der gegenständlichen Software die Offenlegung der Schnittstellen erforderlich sein, ist dies vom Auftraggeber gegen Kostenvergütung beim Auftragnehmer zu beauftragen. Kommt der Auftragnehmer dieser Forderung nicht nach und erfolgt eine Dekompilierung gemäß Urheberrechtsgesetz, sind die Ergebnisse ausschließlich zur Herstellung der Interoperabilität zu verwenden. Missbrauch hat Schadenersatz zur Folge.
                </p>
                <h4>6.4 </h4>
                <p>
                    Bei Erwerb von Nutzungsrechten eines Softwareproduktes räumt der Auftragnehmer dem Nutzer das nicht ausschließliche, zeitlich begrenzte und auf einen Rechner bezogene Recht ein, diese Software zu nutzen. Die Softwarenutzung für den Client ist nur von dem Rechner möglich, an dem die Erstanmeldung zum Server erfolgt. Die Nutzung der Software setzt eine Verbindung zu den Servern der Auftragsnehmer voraus. Die Software protokolliert Vorgänge auf den Servern automatisch. Die Nutzung des Softwareproduktes setzt eine Internetverbindung voraus. 
                </p>
                <br />
                <br />
                <h4>7. Rücktrittsrecht</h4>
                <h4>7.1 </h4>
                <p>
                    Für den Fall der Überschreitung einer vereinbarten Lieferzeit aus alleinigem Verschulden oder rechtswidrigem Handeln des Auftragnehmers ist der Auftraggeber berechtigt, mittels eingeschriebenen Briefes vom betreffenden Auftrag zurückzutreten, wenn auch innerhalb der angemessenen Nachfrist die vereinbarte Leistung in wesentlichen Teilen nicht erbracht wird und den Auftraggeber daran kein Verschulden trifft.
                </p>
                <h4>7.2 </h4>
                <p>
                    Höhere Gewalt, Arbeitskonflikte, Naturkatastrophen und Transportsperren sowie sonstige Umstände, die außerhalb der Einflussmöglichkeit des Auftragnehmers liegen, entbinden den Auftragnehmer von der Lieferverpflichtung bzw. gestatten ihm eine Neufestsetzung der vereinbarten Lieferzeit.
                </p>
                <h4>7.3 </h4>
                <p>
                    Stornierungen durch den Auftraggeber sind nur mit schriftlicher Zustimmung des Auftragnehmers möglich. Ist der Auftragnehmer mit einem Storno einverstanden, so hat er das Recht, neben den erbrachten Leistungen und aufgelaufenen Kosten eine Stornogebühr in der Höhe von 30% des noch nicht abgerechneten Auftragswertes des Gesamtprojektes zu verrechnen.
                </p>
                <br />
                <br />
                <h4>8. Gewährleistung, Wartung, Änderungen</h4>
                <h4>8.1 </h4>
                <p>
                    Mängelrügen sind nur gültig, wenn sie reproduzierbare Mängel betreffen und wenn sie innerhalb von 4 Wochen nach Lieferung der vereinbarten Leistung bzw. bei Individualsoftware nach Programmabnahme gemäß Pkt. 2.4. schriftlich dokumentiert erfolgen. Im Falle der Gewährleistung hat Verbesserung jedenfalls Vorrang vor Preisminderung oder Wandlung. Bei gerechtfertigter Mängelrüge werden die Mängel in angemessener Frist behoben, wobei der Auftraggeber dem Auftragnehmer alle zur Untersuchung und Mängelbehebung erforderlichen Maßnahmen ermöglicht. Die Vermutung der Mangelhaftigkeit gem. § 924 ABGB gilt als ausgeschlossen.
                </p>
                <h4>8.2 </h4>
                <p>
                    Korrekturen und Ergänzungen, die sich bis zur Übergabe der vereinbarten Leistung aufgrund organisatorischer und programmtechnischer Mängel, welche vom Auftragnehmer zu vertreten sind, als notwendig erweisen, werden kostenlos vom Auftragnehmer durchgeführt.
                </p>
                <h4>8.3 </h4>
                <p>
                    Kosten für Hilfestellung, Fehlerdiagnose sowie Fehler- und Störungsbeseitigung, die vom Auftraggeber zu vertreten sind, sowie sonstige Korrekturen, Änderungen und Ergänzungen werden vom Auftragnehmer gegen Berechnung durchgeführt. Dies gilt auch für die Behebung von Mängeln, wenn Programmänderungen, Ergänzungen oder sonstige Eingriffe vom Auftraggeber selbst oder von dritter Seite vorgenommen worden sind.
                </p>
                <h4>8.4 </h4>
                <p>
                    Ferner übernimmt der Auftragnehmer keine Gewähr für Fehler, Störungen oder Schäden, die auf unsachgemäße Bedienung, geänderter Betriebssystemkomponenten, Schnittstellen und Parameter, Verwendung ungeeigneter Organisationsmittel und Datenträger, soweit solche vorgeschrieben sind, anormale Betriebsbedingungen (insbesondere Abweichungen von den Installations- und Lagerbedingungen) sowie auf Transportschäden zurückzuführen sind.
                </p>
                <h4>8.5 </h4>
                <p>
                    Für Programme, die durch eigene Programmierer des Auftraggebers bzw. Dritte nachträglich verändert werden, entfällt jegliche Gewährleistung durch den Auftragnehmer.
                </p>
                <h4>8.6 </h4>
                <p>
                    Soweit Gegenstand des Auftrages die Änderung oder Ergänzung bereits bestehender Programme ist, bezieht sich die Gewährleistung auf die Änderung oder Ergänzung. Die Gewährleistung für das ursprüngliche Programm lebt dadurch nicht wieder auf.
                </p>
                <br />
                <br />
                <h4>9. Haftung</h4>
                <p>
                    Der Auftragnehmer haftet für Schäden, sofern ihm Vorsatz oder grobe Fahrlässigkeit nachgewiesen werden, im Rahmen der gesetzlichen Vorschriften. Die Haftung für leichte Fahrlässigkeit ist ausgeschlossen. Der Ersatz von Folgeschäden und Vermögensschäden, nicht erzielten Ersparnissen, Zinsenverlusten und von Schäden aus Ansprüchen Dritter gegen den Auftragnehmer sind in jedem Fall, soweit gesetzlich zulässig, ausgeschlossen.<br /><br />
                    Der Haftungshöchstbetrag für alle Ansprüche aus der Geschäftsbeziehung zwischen dem Auftraggeber und dem Auftragnehmer ist mit der zwischen dem Auftraggeber und Auftragnehmer vereinbarten Auftragssumme der Höhe nach beschränkt. Soweit Ansprüche gegen den Auftragnehmer bestehen, verjähren diese innerhalb von 6 Monaten nach Erbringung der gegenständlichen Leistung, wenn sie nicht gerichtlich geltend gemacht werden. 
                </p>
                <br />
                <br />
                <h4>10. Loyalität</h4>
                <p>
                    Die Vertragspartner verpflichten sich zur gegenseitigen Loyalität. Sie werden jede Abwerbung und Beschäftigung, auch über Dritte, von Mitarbeitern, die an der Realisierung der Aufträge gearbeitet haben, des anderen Vertragspartners während der Dauer des Vertrages und 12 Monate nach Beendigung des Vertrages unterlassen. Der dagegen verstoßende Vertragspartner ist verpflichtet, pauschalierten Schadenersatz in der Höhe eines Jahresgehaltes des Mitarbeiters zu zahlen.
                </p>
                <br />
                <br />
                <h4>11. Datenschutz, Geheimhaltung</h4>
                <p>
                    Der Auftragnehmer verpflichtet seine Mitarbeiter, die Bestimmungen der DSGVO einzuhalten.
                </p>
                <br />
                <br />
                <h4>12. Sonstiges</h4>
                <p>
                    Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein oder unwirksam werden, so wird hierdurch der übrige Inhalt dieses Vertrages nicht berührt. Die Vertragspartner werden partnerschaftlich zusammenwirken, um eine Regelung zu finden, die den unwirksamen Bestimmungen möglichst nahekommt.
                </p>
                <br />
                <br />
                <h4>13. Schlussbestimmungen</h4>
                <p>
                    Soweit nicht anders vereinbart, gelten die zwischen Vollkaufleuten zur Anwendung kommenden gesetzlichen Bestimmungen ausschließlich nach österreichischem Recht, auch dann, wenn der Auftrag im Ausland durchgeführt wird. Für allfällige Streitigkeiten wird das örtlich und sachlich zuständige Gericht am Geschäftssitz des Auftragnehmers vereinbart (Gericht Standort Wels). Für den Verkauf an Verbraucher im Sinne des Konsumentenschutzgesetzes gelten die vorstehenden Bestimmungen nur insoweit, als das Konsumentenschutzgesetz nicht zwingend andere Bestimmungen vorsieht.
                </p>
            </div>
        );
    }
}